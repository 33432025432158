import React from 'react';

const Ratings = ({ data: chartData, title, total, size }: any) => {
  let totalCusRatings = 0;
  chartData.barData.map((item: any) => (totalCusRatings += item?.value));
  console.log('data', chartData);
  console.log('totalCusRatings', totalCusRatings);
  const data = {
    barData: {
      title: '',
      value: '',
      percent: '',
    },
    textData: {
      title: '',
      value: '',
      totalRatingsNo: totalCusRatings,
    },
  };

  return (
    <div
      className="mb-10 overflow-hidden rounded-lg bg-white px-8 py-10 text-center lg:mb-0 xl:px-[60px]"
      style={{ boxShadow: '0px 1px 3px 0px rgba(166, 175, 195, 0.40)' }}
    >
      <h3 className="mb-[30px] mt-[28px] font-semibold text-dark text-[20px] font-[600]">
        Customer reviews
      </h3>

      <div className="inline-flex flex-wrap items-center justify-center gap-4 rounded-md border-[.5px] border-stroke px-6 py-[10px] border-[#F9FAFB] bg-[#F9FAFB]">
        <div className="mr-3 flex items-center gap-1">
          {chartData.barData.map((item: any, index: number) => {
            let starred = {
              fill: '#FFA645',
              strokeWidth: '1',
              stroke: 'transparent',
            };
            let greyed = {
              fill: '#FFFFFF',
              strokeWidth: '1',
              stroke: '#DFE4EA',
            };
            return (
              !!index && (
                <Star
                  starClass={
                    index <= chartData.textData.value ? starred : greyed
                  }
                />
              )
            );
          })}
        </div>
        <span className="text-base font-medium text-dark dark:text-white">
          {chartData.textData.value} out of 5
        </span>
      </div>

      <p className="pb-10 pt-3 text-base font-medium text-body-color dark:text-dark-6">
        {totalCusRatings} customer ratings
      </p>

      <div className="space-y-4">
        {chartData.barData.reverse().map((item: any, index) => {
          let per = item.value
            ? ((item.value / totalCusRatings) * 100).toFixed(0)
            : 0;
          return (
            item.title !== '0' && (
              <div className="flex items-center justify-between">
                <span className="whitespace-nowrap text-base font-medium text-dark dark:text-white">
                  {item.title} star
                </span>
                <div className="relative mx-3 h-[14px] w-full rounded-full bg-[#F9FAFB]">
                  <div
                    className={`absolute left-0 top-0 h-full rounded-full bg-[#FFA645]`}
                    style={{ width: `${per}%` }}
                  ></div>
                </div>
                <span className="whitespace-nowrap text-base font-medium text-dark dark:text-white">
                  {per}%
                </span>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export const RatingsChart = React.memo(Ratings);

const Star = ({ starClass }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1008_11877)">
        <path
          d="M18.6562 7.46875L12.9999 6.59375L10.4375 1.21875C10.25 0.84375 9.74995 0.84375 9.56245 1.21875L6.99995 6.625L1.37495 7.46875C0.9687 7.53125 0.81245 8.0625 1.12495 8.34375L5.2187 12.5625L4.24995 18.4687C4.18745 18.875 4.5937 19.2188 4.9687 18.9688L10.0624 16.1875L15.1249 18.9688C15.4687 19.1563 15.9062 18.8437 15.8124 18.4687L14.8437 12.5625L18.9374 8.34375C19.1874 8.0625 19.0624 7.53125 18.6562 7.46875Z"
          style={starClass}
          fill={starClass.fill}
          stroke-width={starClass.strokeWidth}
          stroke={starClass.stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_1008_11877">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
