import React from 'react';
import { Box, Checkbox } from '@material-ui/core';

const SystemCheckbox = (props: any) => {
  let { option = {}, selected, indeterminate, disabled } = props;
  return (
    <Box>
      {option.type === 'All' ? (
        <>
          <Checkbox
            disabled={disabled}
            indeterminate={indeterminate}
            checked={selected}
            inputProps={{ 'aria-label': 'indeterminate checkbox' }}
            style={{ marginLeft: option.type === 'single' ? 16 : 0 }}
          />
          {option.title}
        </>
      ) : (
        <>
          <Checkbox
            checked={selected}
            inputProps={{ 'aria-label': 'indeterminate checkbox' }}
            style={{ marginLeft: option.type === 'single' ? 16 : 0 }}
          />
          {option.title}
        </>
      )}
    </Box>
  );
};

export default SystemCheckbox;
