import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import {
  Analytics,
  ClickAndCollect,
  Orders,
  Scan,
  Settlements,
  VerificationOrders,
  HelpDesk,
  Kiosk,
  MPos,
  PriceActivation,
  RevenueReport,
} from 'src/screens';
import Grafana from 'src/screens/grafana';
import CashReport from '../../screens/cashReport';
import CashDesk from '../../screens/cashDesk';
import ItemReport from 'src/screens/itemReport';

const HomeRouter = () => {
  return (
    <Switch>
      <PrivateRoute
        component={ClickAndCollect}
        path="/dashboard/click_and_collect"
        exact
      />
      <PrivateRoute component={Orders} path="/dashboard/orders" exact />
      <PrivateRoute component={CashDesk} path="/dashboard/cashDesk" exact />
      <PrivateRoute component={CashReport} path="/dashboard/cashReport" exact />
      <PrivateRoute component={Kiosk} path="/dashboard/kiosk" exact />
      <PrivateRoute component={MPos} path="/dashboard/mpos" exact />
      <PrivateRoute component={Analytics} path="/dashboard/analytics" exact />
      <PrivateRoute
        component={PriceActivation}
        path="/dashboard/priceActivation"
        exact
      />
      <PrivateRoute component={Scan} path="/dashboard/scan" exact />
      <PrivateRoute
        component={VerificationOrders}
        path="/dashboard/verification"
        exact
      />
      <PrivateRoute
        component={Settlements}
        path="/dashboard/settlements"
        exact
      />

      <PrivateRoute component={HelpDesk} path="/dashboard/helpDesk" exact />
      <PrivateRoute component={RevenueReport} path="/dashboard/revenue" exact />
      <PrivateRoute component={ItemReport} path="/dashboard/itemReport" exact />
      <PrivateRoute component={Grafana} path="/dashboard/grafana" exact />

      <Redirect from="/dashboard" to="/dashboard/orders" />
    </Switch>
  );
};

export default HomeRouter;
