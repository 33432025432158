import produce from 'immer';
import {
  CHANGE_START_DATE,
  CHANGE_END_DATE,
  CHANGE_DELIMITER,
  CHANGE_SETTLEMENTS_SELECTED_RETAILER,
} from '../actions/action_types';

let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const INITIAL_STATE = {
  startDate: yesterday,
  endDate: new Date(),
  delimiters: [],
  selectedRetailer: {},
};

const settlements = produce((draft: any, action: any) => {
  switch (action.type) {
    case CHANGE_START_DATE:
      draft.startDate = action.payload;
      break;
    case CHANGE_END_DATE:
      draft.endDate = action.payload;
      break;
    case CHANGE_DELIMITER:
      draft.delimiters = action.payload;
      break;
    case CHANGE_SETTLEMENTS_SELECTED_RETAILER:
      draft.selectedRetailer = action.payload;
      break;
  }
}, INITIAL_STATE);

export default settlements;
