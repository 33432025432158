import React, { useEffect, useRef } from 'react';
import HomeRouter from 'src/router/HomeRouter';
import {
  Drawer,
  List,
  ListItem,
  Hidden,
  IconButton,
  Typography,
  Button,
  Divider,
  Dialog,
  Slide,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ClickCollectIcon from '../../assets/click_collect.svg';
import OrdersIcon from '../../assets/orders.svg';
import AnalyticsIcon from '../../assets/analytics.svg';
import ScanIcon from '../../assets/scan.svg';
import VerificationIcon from '../../assets/verification.svg';
import CheckIcon from '../../assets/checkIcon.svg';
import MonitorIcon from '../../assets/monitor_outline.svg';
import HelpAndSupportIcon from '../../assets/help.svg';
import UserIcon from '../../assets/user.svg';
import PriceTag from '../../assets/pricetag.svg';
import Smartphone from '../../assets/smartphone.svg';
import { LogoutContext } from 'src/components/LogoutWrapper';
import { useContext } from 'react';
import ArrowDownIcon from 'src/assets/svgIcons/ArrowDownIcon';
import { useDispatch, useSelector } from 'react-redux';
import StoreSelector from './components/StoreSelector';
import { TransitionProps } from '@material-ui/core/transitions';
import FilterIcon from 'src/assets/svgIcons/FilterIcon';
import { SelectedStores } from 'src/components/SelectedStores';
import packageInfo from '../../../package.json';
import IntroModal from 'src/components/IntroModal';
import { get } from 'lodash';
import FoldersIcon from '../../assets/folders.svg';
import CalendarIcon from '../../assets/calendar.svg';
import { datadogRum } from '@datadog/browser-rum';

const drawerWidth = 350;

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100%',
    display: 'flex',
    width: '100vw',
  },
  appBar: {
    [theme.breakpoints.between('md', 'xl')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    width: '100%',
    overflow: 'hidden',
  },
  drawer: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    zIndex: -1,
  },
  drawerPermanant: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    position: 'absolute',
    left: 16,
    top: 32,
    backgroundColor: theme.palette.color_icon_button.main,
    color: theme.palette.color_white.main,
  },
  list: {
    padding: 0,
    flex: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  storeValue: {
    flex: 1,
    marginRight: 10,
    height: 40,
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(228, 233, 242, 0.2)',
    border: 'solid 1px #e4e9f2',
    textTransform: 'none',
    color: '#8f9bb3',
    fontSize: 15,
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: 8,
    },
    textOverflow: 'ellipsis',
  },

  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2000,
  },

  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2000,
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  listItem: {
    height: '64px',
  },
  listItemText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  drawerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  selectStore: { marginBottom: 8, fontSize: 13 },
  storeValueContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  refreshButton: {
    width: 40,
    height: 40,
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(51, 102, 255, 0.1)',
  },
  icon: { width: 20, height: 20 },
  listContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  listContentIcon: { width: 20, height: 20, marginRight: 20 },
  listContentText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  signUpContainer: {
    display: 'flex',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.color_light_grey.main,
    borderRadius: 20,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarIcon: { width: 25, height: 25 },
  userNameContainer: {
    display: 'flex',
    width: '100%',
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  userNameText: {
    fontSize: 18,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  logoutButton: { alignSelf: 'flex-start', marginLeft: -8 },
  logoutButtonText: {
    color: theme.palette.color_text_grey.main,
    fontSize: 12,
  },
  marginTop: { marginTop: 20 },
  link: { textDecoration: 'none' },
  iconRoot: {
    '&:hover': {
      backgroundColor: theme.palette.color_icon_button.main,
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const context = useContext(LogoutContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const intl = useIntl();
  const [openStoreModal, setOpenStoreModal] = React.useState(false);
  const { active_stores } = useSelector((state: any) => state.store);
  const {
    userData,
    permissions: { allow_analytics, allow_settlements },
  } = useSelector((state: any) => state.user);
  const { introVisible } = useSelector((state: any) => state.screens);
  let hasClickAndCollectStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.click_and_collect', false),
  );
  let hasKioskStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.kiosk', false),
  );
  let hasMPosStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.mpos', false),
  );

  // TODO update following methods with cash_desk & cash_report keys as per BE implementation (GPP-5295)
  let hasCashDeskStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.cashier_kiosk', false),
  );
  let hasCashReportStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.cash_desk', true),
  );
  let hasPriceActivationStores = active_stores.some((store: any) =>
    get(
      store,
      'features_applicable.price_activation_link',
      store.features_applicable.price_activation_link?.length > 0,
    ),
  );
  let priceActivationLink = active_stores.find((store: any) => {
    return store.features_applicable.price_activation_link;
  });

  const showRevenueReport =
    active_stores.length === 1 &&
    active_stores[0]['store_type'] === 'MLSEStoreType'
      ? true
      : false;

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');

  const menus = [
    {
      title: 'analytics',
      selected: false,
      linkTo: '/dashboard/analytics',
      default: 'Analytics',
      icon: AnalyticsIcon,
      visible: allow_analytics,
    },
    {
      title: 'products',
      selected: false,
      linkTo: false,
      default: 'Products',
      icon: AnalyticsIcon,
      visible: true,
      children: [
        {
          title: 'click&Collect',
          selected: true,
          linkTo: '/dashboard/click_and_collect',
          default: 'Click & Colect',
          icon: ClickCollectIcon,
          visible: hasClickAndCollectStores,
        },
        {
          title: 'orders',
          selected: false,
          linkTo: '/dashboard/orders',
          default: 'Scan & Go orders',
          icon: OrdersIcon,
          visible: true,
        },
        {
          title: 'cashDesk',
          selected: false,
          linkTo: '/dashboard/cashDesk',
          default: 'CashDesk',
          icon: MonitorIcon,
          visible: hasCashDeskStores,
        },
        {
          title: 'kiosk',
          selected: false,
          linkTo: '/dashboard/kiosk',
          default: 'Kiosk',
          icon: MonitorIcon,
          visible: hasKioskStores,
        },
      ],
    },
    {
      title: 'mPOS',
      selected: false,
      linkTo: '/dashboard/mpos',
      default: 'mPOS',
      icon: Smartphone,
      visible: hasMPosStores,
    },
    {
      title: 'cashReport',
      selected: false,
      linkTo: '/dashboard/cashReport',
      default: 'Cash Report',
      icon: FoldersIcon,
      visible: hasCashDeskStores,
    },
    {
      title: 'priceActivation',
      selected: false,
      linkTo: '/dashboard/priceActivation',
      default: 'Price Activation',
      icon: CalendarIcon,
      type: 'priceActivation',
      visible: hasPriceActivationStores,
    },
    {
      title: 'scanOrder',
      selected: false,
      linkTo: '/dashboard/scan',
      default: 'Scan',
      icon: ScanIcon,
      visible: true,
    },
    {
      title: 'verification',
      selected: false,
      linkTo: '/dashboard/verification',
      default: 'Verifications',
      icon: VerificationIcon,
      visible: true,
    },
    {
      title: 'settlements',
      selected: false,
      linkTo: '/dashboard/settlements',
      default: 'Settlements',
      icon: CheckIcon,
      visible: allow_settlements,
    },
    {
      title: 'report',
      selected: false,
      linkTo: '/dashboard/revenue',
      default: 'Revenue Report',
      icon: FoldersIcon,
      visible: showRevenueReport,
    },
    {
      title: 'itemReport',
      selected: false,
      linkTo: '/dashboard/itemReport',
      default: 'Item Report',
      icon: FoldersIcon,
      visible: showRevenueReport,
    },
    {
      title: 'grafana',
      selected: false,
      linkTo: '/dashboard/grafana',
      default: 'Monitoring',
      type: 'grafana',
      icon: HelpAndSupportIcon,
      visible: !!userData?.email?.includes('mishipay'),
    },
    {
      title: 'helpDesk',
      selected: false,
      linkTo: '/dashboard/helpDesk',
      default: 'Help & Support',
      icon: HelpAndSupportIcon,
      type: 'newTab',
      visible: true,
    },
  ];

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    let timer: any = null;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    try {
      let maskedemail;
      if (
        !userData?.email?.includes('mishipay') ||
        !userData?.username?.includes('mishipay')
      ) {
        let email = userData.email.split('@');
        maskedemail = `****@${email[1]}`;
      }
      datadogRum.setUser({
        id: userData.user_id,
        username: userData.username,
        email: maskedemail,
      });
    } catch (e) {
      console.log('missing userdata', e);
    }
  }, [userData]);

  const handleOpenStoreModal = () => {
    setOpenStoreModal(true);
  };

  const handleCloseStoreModal = (event: object, reason: string) => {
    if (reason !== 'backdropClick') setOpenStoreModal(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const selectedTabContent = (item: any, index: any) => {
    switch (item.type) {
      case 'grafana': {
        return (
          <Link key={index} to={item.linkTo} className={classes.link}>
            <NavItem menu={item.default} img={item.icon} link={item.linkTo} />
          </Link>
        );
      }
      case 'newTab': {
        return (
          <>
            <Divider />
            <Link key={index} to={item.linkTo} className={classes.link}>
              <NavItem menu={item.default} img={item.icon} link={item.linkTo} />
            </Link>
          </>
        );
      }
      case 'priceActivation': {
        return (
          <>
            <NavItem
              menu={item.default}
              img={item.icon}
              itemtype={item.type}
              link={item.linkTo}
            />
          </>
        );
      }
      default: {
        if (item.children) {
          return (
            <NavItem
              menu={item.default}
              img={item.icon}
              link={item.linkTo}
              submenu
            >
              {item.children.map((item: any, index: number) => (
                <Link key={index} to={item.linkTo} className={classes.link}>
                  <DropdownItem
                    menu={item.default}
                    img={item.icon}
                    link={item.linkTo}
                  />
                </Link>
              ))}
            </NavItem>
          );
        }
        return (
          <Link key={index} to={item.linkTo} className={classes.link}>
            <NavItem menu={item.default} img={item.icon} link={item.linkTo} />
          </Link>
        );
      }
    }
  };

  const menuNav = type => {
    if (type === 'priceActivation') {
      window.open(
        priceActivationLink.features_applicable.price_activation_link,
        '_blank',
      );
    }
    if (type === 'newTab') {
      window.open(
        'https://mishipay.atlassian.net/servicedesk/customer/portals',
        '_blank',
      );
    }
  };

  const NavItem = ({
    menu,
    img = '',
    link,
    submenu = false,
    itemtype = false,
    children = [<></>],
  }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close if the esc key is pressed
    useEffect(() => {
      const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
        setDropdownOpen(false);
      };
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
      <li className="relative">
        <div
          ref={trigger}
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
            menuNav(itemtype);
          }}
          className={`
            ${
              window.location.pathname.includes(link)
                ? '!text-primary !border-primary bg-primary-5'
                : 'text-[#637381] border-transparent hover:bg-primary-5'
            }
            ${dropdownOpen ? '!text-primary !border-primary bg-primary-5' : ''} 
          
          capitalize font-[500] text-[16px] no-underline relative flex items-center border-r-4 py-[10px] pl-[35px] pr-[30px] duration-200 hover:border-primary hover:bg-primary-5 hover:text-primary`}
        >
          {menu}
          {submenu && (
            <span
              className={`${
                dropdownOpen === true ? 'rotate-0' : 'rotate-180'
              } absolute right-10 top-1/2 -translate-y-1/2`}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                className="fill-current"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.5899 13.0899C15.2645 13.4153 14.7368 13.4153 14.4114 13.0899L10.0006 8.67916L5.58991 13.0899C5.26447 13.4153 4.73683 13.4153 4.41139 13.0899C4.08596 12.7645 4.08596 12.2368 4.41139 11.9114L9.41139 6.9114C9.73683 6.58596 10.2645 6.58596 10.5899 6.9114L15.5899 11.9114C15.9153 12.2368 15.9153 12.7645 15.5899 13.0899Z"
                ></path>
              </svg>
            </span>
          )}
        </div>
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className={`${dropdownOpen === true ? 'block' : 'hidden'} `}
        >
          <ul className="pl-0">{children}</ul>
        </div>
      </li>
    );
  };

  const DropdownItem = ({ link = '', menu, img = '' }) => {
    return (
      <li>
        <div
          className={`${
            window.location.pathname.includes(link)
              ? 'bg-primary-5 !text-primary !border-primary'
              : 'text-[#637381]'
          }
          flex border-r-4 border-transparent py-[10px] pl-[65px] duration-200 hover:text-primary hover:bg-primary-5 hover:border-primary capitalize font-[500] text-[16px] no-underline`}
        >
          {menu}
        </div>
      </li>
    );
  };

  const Divider = () => {
    return <div className="mx-[35px] my-[15px] h-[1px] bg-[#DFE4EA]"></div>;
  };

  const drawerContent = () => (
    <>
      <Box className={classes.drawerContainer}>
        {/* <Typography variant="subtitle2" className={classes.selectStore}>
          Select Region, Retailer and Store
        </Typography> */}

        <Box className={classes.storeValueContainer}>
          <Button
            variant="outlined"
            className={classes.storeValue}
            onClick={handleOpenStoreModal}
            endIcon={<ArrowDownIcon />}
            startIcon={<FilterIcon />}
            style={{ marginBottom: 4 }}
          >
            SELECT
          </Button>
          <SelectedStores />
          {/* <Button onClick={() => getStores()} className={classes.refreshButton}>
            <img className={classes.icon} src={RefreshIcon} alt="refresh" />
          </Button> */}
        </Box>
      </Box>

      {/* Select store end */}

      <div className="flex h-screen w-full flex-col justify-between overflow-y-scroll bg-white shadow-testimonial-6">
        <div>
          <nav className="pt-[30px]">
            <ul className="pl-0">
              {menus.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  {item?.visible ? selectedTabContent(item, index) : <></>}
                </React.Fragment>
              ))}
            </ul>
          </nav>
        </div>
        <div className="py-[30px] pl-[35px]">
          <div className="flex items-center">
            <div className="mr-4 h-[50px] w-full max-w-[50px] rounded-full bg-primary">
              <span className="text-[#ffffff] font-[600] text-[20px] leading-[50px] capitalize">
                {userData.username.charAt(0)}
              </span>
            </div>
            <div>
              <h6 className="mb-0 text-left text-[#111928] text-[16px] font-[500] capitalize">
                {userData.username}
              </h6>
              <p
                className="mb-0 text-sm text-[#637381] text-[14px] font-[400] text-left"
                onClick={() => {
                  context.logoutUser();
                }}
              >
                Log Out
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Box className={classes.root}>
        <Hidden only={['md', 'lg', 'xl']} implementation="css">
          <Drawer
            variant="temporary"
            className={classes.drawer}
            classes={{ paper: classes.drawer }}
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            {drawerContent()}
          </Drawer>
        </Hidden>
        <Hidden only={['xs', 'sm']} implementation="css">
          <Drawer
            variant="permanent"
            className={classes.drawerPermanant}
            classes={{ paper: classes.drawerPermanant }}
            anchor="left"
          >
            {drawerContent()}
          </Drawer>
        </Hidden>
        <Box className={classes.appBar}>
          <Hidden only={['md', 'lg', 'xl']} implementation="css">
            <IconButton
              className={classes.menu}
              classes={{ root: classes.iconRoot }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <HomeRouter />
        </Box>
      </Box>
      {openStoreModal && (
        <Dialog
          keepMounted
          disableEnforceFocus
          disableEscapeKeyDown
          open={openStoreModal}
          TransitionComponent={Transition}
          scroll="paper"
          onClose={handleCloseStoreModal}
          fullWidth={true}
        >
          <StoreSelector onClose={handleCloseStoreModal} />
        </Dialog>
      )}
      {introVisible && (
        <Dialog
          disableEnforceFocus
          disableEscapeKeyDown
          open={introVisible}
          TransitionComponent={Transition}
          scroll="paper"
          onClose={() => {
            dispatch({
              type: 'TOGGLE_INTRO',
              payload: false,
            });
          }}
          fullWidth={true}
        >
          <IntroModal
            onClose={() => {
              dispatch({
                type: 'TOGGLE_INTRO',
                payload: false,
              });
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default Home;
