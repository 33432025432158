import React from 'react';
import { useCookies } from 'react-cookie';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, restricted, ...rest }: any) => {
  const [cookies] = useCookies();
  return (
    <Route
      {...rest}
      render={(props: any) =>
        restricted && cookies.app_token ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
