import React, { useMemo } from 'react';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { lightTheme, darkTheme } from 'src/assets/theme/theme';
import { useSelector } from 'react-redux';

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useSelector((state: any) => state.theme);
  const themeSelected = useMemo(() => {
    return theme === 'light' ? lightTheme : darkTheme;
  }, [theme]);
  return (
    <MuiThemeProvider theme={themeSelected}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
