import produce from 'immer';
import {
  CHANGE_PAGE_NUMBER,
  CHANGE_ROWS_PER_PAGE,
  DATE_TIME_AMOUNT_FILTERS,
} from '../actions/action_types';

const INITIAL_STATE = {
  pageNumber: { orders: 1, ccOrders: 1, kiosk: 1, cashDesk: 1, cashReport: 1 },
  pageSize: 25,
  dateTimeAmountFilters: {},
};

const orders = produce((draft: any, action: any) => {
  switch (action.type) {
    case CHANGE_PAGE_NUMBER:
      draft.pageNumber = action.payload;
      break;
    case CHANGE_ROWS_PER_PAGE:
      draft.pageSize = action.payload;
      break;
    case DATE_TIME_AMOUNT_FILTERS:
      draft.dateTimeAmountFilters = action.payload;
      break;
  }
}, INITIAL_STATE);

export default orders;
