import React from 'react';
import {
  Box,
  Select,
  MenuItem,
  Checkbox,
  Input,
  ListItemText,
  Typography,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import DatePicker from '../analytics/components/datePicker';
import { makeStyles } from '@material-ui/core/styles';
import { findIndex, remove } from 'lodash';
import axios from 'axios';

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    paddingLeft: 16,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 32,
    },
    alignItems: 'center',
    marginRight: 16,
    flexWrap: 'wrap',
  },
  imageList: {
    width: 500,
    height: 450,
  },
}));

const Grafana = () => {
  const classes = useStyles();
  let month = new Date();
  month.setMonth(month.getMonth() - 1);
  month.setHours(0, 0, 0, 0);
  const [panels, setPanels] = useState<any>([
    {
      id: 1,
      panel_id: 17,
    },
    {
      id: 2,
      panel_id: 16,
    },
    { id: 3, panel_id: 19, style: { height: '25%', width: '25%' } },
    { id: 4, panel_id: 8, style: { height: '25%', width: '25%' } },
    {
      id: 5,
      panel_id: 20,
      style: { height: '25%', width: '25%' },
    },
    {
      id: 6,
      panel_id: 21,
      style: { height: '25%', width: '25%' },
    },
    // {
    //   id: 7,
    //   panel_id: 22,
    //   style: { height: '25%', width: '25%' },
    // },
    // {
    //   id: 8,
    //   panel_id: 7,
    //   style: { height: '25%', width: '25%' },
    // },
    // { id: 9, panel_id: 26, style: { height: '25%', width: '25%' } },
    // {
    //   id: 10,
    //   panel_id: 25,
    //   style: { height: '25%', width: '25%' },
    // },
    // {
    //   id: 11,
    //   panel_id: 24,
    //   style: { height: '25%', width: '25%' },
    // },
  ]);
  const [selectedRetailer, selectRetailer] = useState(['All']);
  const [startDate, setStartDate] = useState(month);
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const retailers = [
    'All',
    'MUJI',
    'Flying Tiger Copenhagen',
    'Paradies Lagardère',
    "Fast Eddy's",
    'Eroski',
    'Londis',
    'SPAR',
    'ExpressLane',
    'MishiPay',
    'Decathlon',
    'Dufry',
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleRetailerChange = (
    event: React.ChangeEvent<{ value: any }>,
    child: any,
  ) => {
    if (child.props.value === 'All') {
      selectRetailer(['All']);
    } else if (
      child.props.value !== 'All' &&
      event.target.value.includes('All')
    ) {
      let valueCopy = [...event.target.value];
      remove(valueCopy, (item: any) => item === 'All');
      selectRetailer(valueCopy);
    } else {
      selectRetailer(event.target.value as string[]);
    }
  };

  const filters = () => {
    return (
      <Box
        // style={{
        //   display: 'flex',
        //   alignItems: 'center',
        //   marginRight: 16,
        //   flexWrap: 'wrap',
        // }
        className={classes.header}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <InputLabel shrink placeholder="Select Retailer">
            Retailer
          </InputLabel>
          <Select
            multiple
            value={selectedRetailer}
            onChange={handleRetailerChange}
            input={<Input placeholder="Select Retailer" />}
            renderValue={selected => (selected as string[]).join(', ')}
            MenuProps={MenuProps}
            label="Retailer"
            style={{ minWidth: 160 }}
          >
            {retailers.map((name: any) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={selectedRetailer.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>

        <DatePicker
          label="Start Date"
          date={startDate}
          handleDateChange={setStartDate}
          style={{ maxWidth: 160, width: 180, marginLeft: 8 }}
        />
        <DatePicker
          label="End Date"
          date={endDate}
          handleDateChange={setEndDate}
          style={{ maxWidth: 160, width: 180, marginLeft: 8 }}
        />
      </Box>
    );
  };
  const headers = {
    Authorization:
      'Bearer ' +
      'eyJrIjoiRHIwcVdsaXdxUGRGTUMzWUlZVW9CZGVyNjBFV1FZc08iLCJuIjoiZGFzaGJvYXJkIiwiaWQiOjF9',
  };

  function getBase64(url: any) {
    return axios
      .get(url, {
        headers: headers,
        responseType: 'arraybuffer',
      })
      .then((response: any) => {
        let blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let image = URL.createObjectURL(blob);

        return `data:${response.headers['content-type']};base64,${btoa(
          String.fromCharCode(...new Uint8Array(response.data)),
        )}`;
      })
      .catch(e => {
        console.log({ e });
        setError(true);
      });
  }

  const panelRequest = async (panel_id: string) => {
    let retailer = selectedRetailer.join('&var-retailer=');
    let val = await getBase64(
      `https://metrics.mishipay.com/render/d-solo/fN7GUYi7k/retail-import-export-jobs?orgId=1&from=${startDate.getTime()}&to=${endDate.getTime()}&var-retailer=${retailer}&panelId=${panel_id}`,
    );
    return val;
  };

  const methodThatReturnsAPromise = (panel: any, panels: any) => {
    return new Promise(async (resolve, reject) => {
      if (!error) {
        let res = await panelRequest(panel.panel_id);
        // resolve(panel);
        let index = findIndex(panels, { id: panel.id });
        panels.splice(index, 1, {
          ...panel,
          imageVal: res,
        });
        resolve(panels);
      } else reject();
    });
  };

  useEffect(() => {
    // const getPanels = async () => {
    //   setLoading(true);
    //   setError(false);
    //   let result = [...panels].reduce(
    //     (accumulatorPromise: any, nextID: any) => {
    //       return accumulatorPromise.then(() => {
    //         return methodThatReturnsAPromise(nextID, panels);
    //       });
    //     },
    //     Promise.resolve(),
    //   );
    //   result.then((e: any) => {
    //     setPanels(e);
    //     setLoading(false);
    //     error && setError(false);
    //   });
    // };

    const getPanels = () => {
      setLoading(true);
      setError(false);
      let result = Promise.allSettled(
        panels.map((panel: any) => panelRequest(panel.panel_id)),
      )
        .then((results: any) => {
          let temp = [...panels];
          temp.map((panel: any, index) => {
            temp[index].imageVal = results[index].value;
          });
          setPanels(temp);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log({ Error });
          setError(true);
        });
    };

    getPanels();
  }, [selectedRetailer, startDate, endDate]);

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 16,
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
          Monitoring
        </Typography>
        <>{filters()}</>
      </Box>

      <Box style={{ padding: 8 }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
          }}
        >
          {error ? (
            <Typography>Something went wrong!!! </Typography>
          ) : (
            <>
              {loading ? (
                <CircularProgress style={{ alignSelf: 'center' }} />
              ) : (
                <>
                  {panels.map((panel: any) => {
                    return (
                      <img
                        src={panel?.imageVal}
                        style={
                          panel.style
                            ? panel.style
                            : { height: '45%', width: '45%', margin: 4 }
                        }
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Grafana;
