import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowRight from 'src/assets/svgIcons/ArrowRight';
import SearchIcon from 'src/assets/svgIcons/SearchIcon';
import { ordersApi } from 'src/services/orders';
import { getSelectedStoreAppClipIds, goToOrderDetails } from 'src/utils';
import { Alert } from '@material-ui/lab';
import TuneIcon from '../../assets/tune.svg';
import DateTimeFilter from '../DateTimeFilter';

const useStyles = makeStyles((theme: any) => ({
  mainContainer: {
    display: 'flex',
  },
  input: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    [theme.breakpoints.between('lg', 'xl')]: {
      left: 150,
    },
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 5000,
  },
  dateTimeFilterIcon: {
    borderRadius: 6,
    marginLeft: 9,
    width: 48,
    height: 48,
    '&:hover': {
      backgroundColor: '#222B45',
    },
  },
  filterIndicator: {
    position: 'absolute',
    top: -7,
    right: 4,
    borderRadius: '50%',
    background: '#4180E9',
    height: 12,
    width: 12,
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const InputAndPopper = ({
  type = null,
  items = null,
  order_type = null,
  dateTimeAmountFilters = {},
  applyFiltersHandler,
}: any) => {
  const classes = useStyles();
  const {
    selected_regions,
    selected_retailers,
    active_stores,
    stores,
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);
  const intl = useIntl();
  const history = useHistory();
  const [input, setInput] = useState('');
  const [cookies] = useCookies();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [orderData, setOrderData] = React.useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [showFilters, setShowFilters] = useState(false);

  const closeFiltersHandler = () => {
    setShowFilters(false);
  };

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  const onChangeText = (e: any) => {
    setAnchorEl(e.currentTarget);
    setInput(e.target.value);
  };

  const checkIfNotFlyingTigerStores = () =>
    active_stores.some(
      (store: any) => store.store_type !== 'FlyingTigerStoreType',
    );

  const modifySearchString = (searchString: string) => {
    if (checkIfNotFlyingTigerStores()) {
      return searchString;
    } else if (
      searchString.startsWith('100') ||
      searchString.startsWith('1000') ||
      searchString.startsWith('10000') ||
      searchString.startsWith('100000')
    ) {
      return searchString;
    } else {
      if (searchString.length === 7) {
        return '10' + searchString;
      } else if (searchString.length === 6) {
        return '100' + searchString;
      } else if (searchString.length === 5) {
        return '1000' + searchString;
      } else if (searchString.length === 4) {
        return '10000' + searchString;
      } else if (searchString.length === 3) {
        return '100000' + searchString;
      } else return searchString;
    }
  };

  const fetchOrdersData = async () => {
    let store_ids = getSelectedStoreAppClipIds(
      stores,
      selected_retailers,
      selected_regions,
      active_stores,
      include_demo_stores,
      include_inactive_stores,
    );
    let regions = selected_regions.map((region: any) => region.value);
    let store_types = selected_retailers.map((retailer: any) => retailer.value);

    let searchString = modifySearchString(input);
    searchString !== input && setInput(searchString);
    let params = {
      v1: 'new_base_v3',
      store_types: store_types.join(),
      app_clip_ids: store_ids.join(),
      regions: regions.join(),
      purchase_type: order_type,
      page: 1,
      o_id: searchString,
      token: cookies.app_token,
      include_demo: include_demo_stores,
      include_inactive: include_inactive_stores,
      page_size: 50,
    };
    return ordersApi.query(params);
  };

  const { isLoading: orderFetchLoading, mutate: fetchOrders } = useMutation(
    fetchOrdersData,
    {
      onSuccess: (data: any) => {
        setInput('');
        if (get(data, 'data.data', []).length > 1) {
          setOpen(true);
          setOrderData(get(data, 'data.data', []));
        } else {
          if (get(data, 'data.data', []).length === 0) {
            setError(true);
            setErrorMessage('No orders found');
          } else {
            goToOrderDetails({
              order_id: get(data, 'data.data[0].order_id', ''),
              type:
                order_type === 'click_and_collect'
                  ? 'click_and_collect'
                  : 'orders',
              from: 'orders',
              history,
            });
          }
        }
      },

      onError: error => {
        setError(true);
        setErrorMessage('Error fetching orders');
      },
    },
  );

  const onPressEnter = (e: any) => {
    if (e.key === 'Enter') {
      if (type === 'verification' && items) {
        let item = items.filter((item: any) => item.code == input)[0];
        if (item) {
          setInput('');
          history.push({
            pathname: `/verificationDetails/`,
            state: {
              items: JSON.stringify(item),
            },
          });
        } else {
          setError(true);
          setErrorMessage('No verification orders found');
        }
      } else {
        fetchOrders();
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const goTo = (order_id: any) => {
    handleClose();
    goToOrderDetails({
      order_id,
      type: order_type === 'click_and_collect' ? 'click_and_collect' : 'orders',
      from: 'orders',
      history,
    });
  };

  return (
    <>
      <Box className={classes.mainContainer}>
        <div className="relative">
          <input
            type="text"
            name="search"
            placeholder={
              type === 'verification'
                ? intl.formatMessage({ id: 'searchVerificationId' })
                : intl.formatMessage({ id: 'searchOrderId' })
            }
            onChange={onChangeText}
            onKeyDown={onPressEnter}
            autoComplete="off"
            value={input}
            className="w-full h-[48px] bg-transparent rounded-md border border-stroke dark:border-dark-3 py-[10px] pr-3 pl-12 text-[#637381] outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2"
          />
          <span className="absolute top-1/2 left-4 -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M15.35 14.5072L11.225 11.1572C12.925 8.83223 12.75 5.50723 10.625 3.40723C9.5 2.28223 8 1.65723 6.4 1.65723C4.8 1.65723 3.3 2.28223 2.175 3.40723C-0.149998 5.73223 -0.149998 9.53223 2.175 11.8572C3.3 12.9822 4.8 13.6072 6.4 13.6072C7.925 13.6072 9.35 13.0322 10.475 12.0072L14.65 15.3822C14.75 15.4572 14.875 15.5072 15 15.5072C15.175 15.5072 15.325 15.4322 15.425 15.3072C15.625 15.0572 15.6 14.7072 15.35 14.5072ZM6.4 12.4822C5.1 12.4822 3.9 11.9822 2.975 11.0572C1.075 9.15723 1.075 6.08223 2.975 4.20723C3.9 3.28223 5.1 2.78223 6.4 2.78223C7.7 2.78223 8.9 3.28223 9.825 4.20723C11.725 6.10723 11.725 9.18223 9.825 11.0572C8.925 11.9822 7.7 12.4822 6.4 12.4822Z"
                fill="#6B7280"
              />
            </svg>
          </span>
        </div>
        {(order_type === 'kiosk' ||
          order_type === 'scan_and_go' ||
          order_type === 'cashier_kiosk') && (
          <IconButton
            className={`bg-primary ${classes.dateTimeFilterIcon}`}
            onClick={() => {
              setShowFilters(prevState => !prevState);
            }}
          >
            {dateTimeAmountFilters.isApplied && (
              <span className={classes.filterIndicator} />
            )}
            <img src={TuneIcon} alt="filters" />
          </IconButton>
        )}
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        style={{
          backgroundColor: 'white',
          zIndex: 5000,
          width: 385,
          marginLeft: 20,
          marginTop: 20,
          marginRight: 20,
        }}
      >
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  style={{
                    height: '70vh',
                    overflow: 'auto',
                    borderRadius: '6px',
                    boxShadow: '0px 1px 3px 0px rgba(166, 175, 195, 0.40)',
                  }}
                >
                  {orderData.map((item: any, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => goTo(item.order_id)}
                      className="pt-[6px] bg-[#FFF] text-[#222b45] hover:bg-[#3758f90d] hover:text-primary"
                    >
                      <Box style={{ padding: 5 }}>
                        <Typography
                          style={{
                            fontSize: 13,
                            fontWeight: 'bold',
                          }}
                        >{`#${item.o_id} - ${
                          item.date
                            ? `${moment(item.date).format(
                                'DD/MM/YY HH:mm',
                              )} UTC(${moment(item.date)
                                .utc()
                                .format('HH:mm')})`
                            : ''
                        }`}</Typography>
                        <Typography style={{ fontSize: 12, color: '#8f9bb3' }}>
                          {item.store_name}
                        </Typography>
                        <Box
                          style={{ position: 'absolute', right: 0, top: 18 }}
                        >
                          <ArrowRight />
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      {showFilters && (
        <DateTimeFilter
          {...dateTimeAmountFilters}
          closeFiltersHandler={closeFiltersHandler}
          applyFiltersHandler={applyFiltersHandler}
        />
      )}
    </>
  );
};

export default InputAndPopper;
