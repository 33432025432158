import {
  Box,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

const RefundSelector = (props: any) => {
  const intl = useIntl();
  let {
    quantity,
    group_items_qty_editable,
    item,
    items_to_refund,
    order_status,
    setItemsToRefund,
    cc_order_status,
  } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState(quantity.length - 1);
  const displayValue = quantity[selectedIndex];
  const showRefunds =
    ['verified', 'verification_failed', 'incorrect'].includes(order_status) ||
    cc_order_status;

  const toggleRefunds = (event: any) => {
    let flag = event.target.checked;
    setItemsToRefund((draft: any) => {
      let pos = draft.findIndex(
        (product: any) => product.item_id === item.item_id,
      );
      if (flag) {
        let payload = {
          ...item,
          selected: false,
          qty_refund: quantity.length,
          unique_id: uuidv4(),
        };
        draft.push(payload);
      } else draft.splice(draft[pos], 1);
    });
    setChecked(flag);
  };

  const handleQuantity = (event: any) => {
    let index = event?.target?.value;
    setItemsToRefund((draft: any) => {
      let pos = draft.findIndex(
        (product: any) => product.item_id === item.item_id,
      );
      if (pos != -1) {
        draft[pos]['selected'] = false;
        draft[pos]['qty_refund'] = index;
        draft[pos]['unique_id'] = uuidv4();
      }
    });
    setSelectedIndex(index - 1);
  };

  useEffect(() => {
    if (items_to_refund.length == 0) {
      setChecked(false);
    }
  }, [items_to_refund]);

  useEffect(() => {
    handleQuantity({ target: { value: item.qty } });
  }, [checked]);

  if (showRefunds) {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '8px 0px',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          value={intl.formatMessage({ id: 'refund' })}
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={toggleRefunds}
            />
          }
          label={intl.formatMessage({ id: 'refund' })}
          labelPlacement="end"
        />
        {checked && group_items_qty_editable ? (
          <Select
            style={{ height: 40, minWidth: 210 }}
            value={displayValue}
            onChange={handleQuantity}
            placeholder="Select Quantity"
          >
            {quantity.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>{`${intl.formatMessage({
                id: 'qty',
              })} : ${item}`}</MenuItem>
            ))}
          </Select>
        ) : (
          <></>
        )}
      </Box>
    );
  } else return <></>;
};

export default RefundSelector;
