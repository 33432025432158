import produce from 'immer';
import { CHANGE_THEME, CHANGE_THEME_VARIENT } from '../actions/action_types';

const INITIAL_STATE = {
  theme: 'light',
  themeVarient: 'blue',
};

const theme = produce((draft: any, action: any) => {
  switch (action.type) {
    case CHANGE_THEME:
      draft.theme = action.payload;
      break;
    case CHANGE_THEME_VARIENT:
      draft.themeVarient = action.payload;
      break;
  }
}, INITIAL_STATE);

export default theme;
