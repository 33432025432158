import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateRange } from 'react-date-range';
import './dateRange.css';
const useStyles = makeStyles((theme: any) => ({}));

const DateRangePicker = (props: any) => {
  let {
    label,
    date,
    handleDateChange,
    style = {},
    startDate,
    endDate,
    isFromDateTimeFilter,
  } = props;
  let [dropdownOpen, setDropdownOpen] = useState(false);
  const classes = useStyles();
  const [title, setTitle] = useState('Date Range');
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  let useClickOutside = handler => {
    let domNode = useRef<HTMLInputElement>();

    useEffect(() => {
      let maybeHandler = event => {
        if (!domNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener('mousedown', maybeHandler);

      return () => {
        document.removeEventListener('mousedown', maybeHandler);
      };
    });

    return domNode;
  };
  let domNode = useClickOutside(() => {
    setDropdownOpen(false);
  });
  const handleSelect = date => {
    setState([date]);
    let startDate = new Date(date.startDate);
    let endDate = new Date(date.endDate);
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      startDate,
    );
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(
      startDate,
    );
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      startDate,
    );

    let eyear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      endDate,
    );
    let emonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(
      endDate,
    );
    let eday = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      endDate,
    );
    setTitle(`${month} ${day}, ${year} - ${emonth} ${eday}, ${eyear}`)
    if (endDate.getTime() > startDate.getTime()) {
      setDropdownOpen(!dropdownOpen);
      handleDateChange(date);
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
          console.log('drop', dropdownOpen);
        }}
        className={`w-[280px] flex items-center border-[#D1D5DB] border-solid border-[1px] rounded-[6px] px-[16px] py-[12px] bg-white text-base font-medium text-[#111928]`}
      >
        {title}
        <span className="pl-4 mr-0 ml-auto">
          <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current"
          >
            <path d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4063 5.65625 17.6875 5.9375C17.9687 6.21875 17.9687 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1563 10.1875 14.25 10 14.25Z" />
          </svg>
        </span>
      </button>
      <div
        ref={domNode}
        className={`${
          dropdownOpen
            ? 'mt-[10px] z-50 opacity-100 block absolute xs:left-0 xs:right-0 md:left-[inherit] md:right-[inherit]'
            : 'top full hidden opacity-0'
        }`}
      >
        <div style={{ color: '#000 !important' }}>
          <DateRange
            editableDateInputs={false}
            onChange={item => {
              handleSelect(item.selection);
            }}
            moveRangeOnFirstSelection={false}
            maxDate={new Date()}
            ranges={state}
            rangeColors={['#3758F9']}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
