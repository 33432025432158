import React, { useState } from 'react';
import {
  Typography,
  Toolbar,
  Box,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Button,
  Dialog,
  Slide,
  Card,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  ClickAwayListener,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import MaterialTable, { Icons } from 'material-table';
import { ChevronRight, ArrowDownward, CloudDownload } from '@material-ui/icons';
import {
  currencyFormat,
  getCurrency,
  getDate,
  getDateDifference,
  updateDecimals,
} from '../../utils';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useQuery, useQueryClient } from 'react-query';
import {
  downloadPayoutReportsApi,
  downloadPoslogReportsApi,
  payoutReportApi,
  poslogReportApi,
} from 'src/services/settlements';
import CheckIcon from 'src/assets/svgIcons/CheckIcon';
import QuestionIcon from 'src/assets/svgIcons/QuestionIcon';
import { NavigationTopBar } from 'src/components';
import { DatePicker } from '../analytics/components';

const tableIcons: Icons = {
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flex: 1,
    height: '100%',
    flexWrap: 'wrap',
    padding: 16,
    backgroundColor: '#e8ecf3',
  },
  paper: {
    minHeight: 280,
    width: 350,
    padding: 16,
  },
  title: {
    textAlign: 'center',
    flexGrow: 1,
  },
  filters: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: 16,
    flexWrap: 'wrap',
  },
  toolbar: {
    backgroundColor: 'blue',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sub_toolbar: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#e8ecf3',
    flexWrap: 'wrap',
  },
  textbox1: {
    borderRadius: 4,
    marginRight: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  textbox2: {
    paddingLeft: 16,
    borderRadius: 4,
    marginRight: 8,
    minWidth: 150,
  },
  statusContainer: {
    borderRadius: 4,
    marginRight: 8,
    padding: 20,
  },
}));

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SettlementReports = (props: any) => {
  let { location } = props;
  const { selectedRetailer, delimiters } = useSelector(
    (state: any) => state.settlements,
  );

  let type = get(location, 'state.type', 'poslog_recon');
  let filters = get(location, 'state.filters', []);
  let regionData = get(location, 'state.regionData', []);

  let icon = (matched: any) =>
    matched === true ? (
      <CheckIcon htmlColor={'#00e096'} />
    ) : matched === false ? (
      <QuestionIcon htmlColor={'#ffaa00'} />
    ) : (
      <></>
    );
  let defaultPsp = get(location, 'state.psp', '');
  let defaultStartDate = get(location, 'state.startDate', new Date());
  let defaultEndDate = get(location, 'state.endDate', new Date());
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [region, setRegion] = useState(regionData?.region);
  const [psp, setPsp] = useState(defaultPsp);
  const [toastVisible, showToast] = React.useState(false);
  const [downloadSpinner, setSpinner] = useState(false);
  const [downladDialogVisible, toggleDownloadDialog] = useState(false);
  const [selectedDelimiter, changeDelimiter] = useState(delimiters[0].name);
  const [cookies] = useCookies();

  let tableRef = React.createRef();
  const history = useHistory();

  const downloadReport = async () => {
    try {
      toggleDownloadDialog(false);
      setSpinner(true);
      let token = cookies['app_token'];
      const onSuccess = ({ data = {} }: any) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName =
          type === 'poslog_recon'
            ? 'Settlement_' +
              region +
              '_' +
              getDate(startDate) +
              '_to_' +
              getDate(endDate) +
              '.csv'
            : 'Payout_' +
              psp +
              '_' +
              region +
              '_' +
              getDate(startDate) +
              '_to_' +
              getDate(endDate) +
              '.csv';
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        setSpinner(false);
      };
      const onFailure = (error: Error) => {
        setSpinner(false);
      };
      let storeType = selectedRetailer?.value;
      let params = { v1: true, token: token };
      let selectedDelimiterObject = delimiters.find(
        (item: any) => item.name === selectedDelimiter,
      );

      if (type === 'poslog_recon') {
        let data = {
          start_date: getDate(startDate),
          end_date: getDate(endDate),
          store_type: storeType,
          region: region,
          column_delimiter: selectedDelimiterObject.column_delimiter,
          amount_delimiter: selectedDelimiterObject.amount_delimiter,
        };
        return await downloadPoslogReportsApi
          .create(params, data)
          .then(onSuccess)
          .catch(onFailure);
      } else {
        let data = {
          start_date: getDate(startDate),
          end_date: getDate(endDate),
          store_type: storeType,
          region: region,
          psp: psp,
          column_delimiter: selectedDelimiterObject.column_delimiter,
          amount_delimiter: selectedDelimiterObject.amount_delimiter,
        };
        return await downloadPayoutReportsApi
          .create(params, data)
          .then(onSuccess)
          .catch(onFailure);
      }
    } catch (e) {
      setSpinner(false);
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason: any,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    showToast(false);
  };

  const fetchReports = async () => {
    try {
      if (!getDateDifference(startDate, endDate)) {
        let storeType = selectedRetailer?.value;
        let params = { v1: true, token: cookies.app_token };
        if (type === 'poslog_recon') {
          let data = {
            store_type: storeType,
            start_date: getDate(startDate),
            end_date: getDate(endDate),
            region: region,
          };
          return poslogReportApi.create(params, data).then((res: any) => {
            return res?.data ? res?.data : {};
          });
        } else {
          let data = {
            start_date: getDate(startDate),
            end_date: getDate(endDate),
            store_type: storeType,
            region: region,
            psp: psp,
          };
          return payoutReportApi.create(params, data).then((res: any) => {
            return res?.data ? res?.data : {};
          });
        }
      } else showToast(true);
    } catch (e) {
      console.log({ e });
    }
  };

  const {
    isLoading,
    data = {},
    isFetching,
    error,
  }: any = useQuery(
    [
      'analyticsData',
      'store_id',
      startDate,
      endDate,
      region,
      psp,
      selectedRetailer,
    ],
    fetchReports,
    { refetchOnWindowFocus: false },
  );

  const classes = useStyles();

  const appendCurrency = (currency: any, value: any) => {
    if (currency && value) {
      let number = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyFormat(currency),
      }).format(value);
      return number;
    }
    // return isNaN(Number(number))
    //   ? '--'
    //   : `${getCurrency(currency)} ${number
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  const renderStatusIcon = (
    matched: boolean,
    size: string = 'small',
    pending: any,
  ) => {
    let sizeVariants: any = {
      tiny: { height: 16, wiidth: 16 },
      small: { height: 24, wiidth: 24 },
      big: { height: 28, wiidth: 28 },
    };

    if ((pending && matched == null) || matched == undefined)
      return <Typography style={{ color: '#a9a9a9' }}>Pending</Typography>;
    return icon(matched);
  };

  const renderRegion = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <InputLabel
          shrink
          id="demo-simple-select-label"
          style={{
            marginLeft: 16,
            marginBottom: 8,
            fontWeight: 'bold',
            color: '#8992a3',
          }}
        >
          REGION
        </InputLabel>
        <Select
          id="region"
          value={region}
          variant={'standard'}
          label={<FormattedMessage id="region" defaultMessage="Region" />}
          style={{
            minWidth: 120,
            marginRight: 8,
            marginLeft: 8,
          }}
          onChange={event => {
            setRegion(event.target.value);
          }}
        >
          {filters.map((item: any) => {
            return (
              <MenuItem value={item.region}>
                <div
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                  }}
                >
                  <img
                    src={
                      'https://static-assets.mishipay.com/country_flags/svg/' +
                      item?.region.toLocaleLowerCase() +
                      '.svg'
                    }
                    style={{ height: 24, width: 24, marginRight: 8 }}
                  />
                  <Typography variant="subtitle1">
                    {item.region_name}
                  </Typography>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  };

  const renderPsp = () => {
    if (type !== 'poslog_recon') {
      let pspList = filters.find((item: any) => item.region === region).pspList;
      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <InputLabel
            shrink
            id="demo-simple-select-label"
            style={{
              marginLeft: 16,
              marginBottom: 8,
              fontWeight: 'bold',
              color: '#8992a3',
            }}
          >
            PSP
          </InputLabel>
          <Select
            id="psp"
            value={psp}
            variant={'standard'}
            style={{
              minWidth: 150,
              marginRight: 8,
              marginLeft: 8,
            }}
            onChange={event => setPsp(event.target.value)}
          >
            {pspList.map((item: any) => (
              <MenuItem value={item.psp}>
                <div>
                  <Typography variant="subtitle1">{item.psp_title}</Typography>
                </div>
              </MenuItem>
            ))}
          </Select>
        </Box>
      );
    } else return <></>;
  };

  const filterComponent = () => {
    return (
      <Box className={classes.filters}>
        {renderRegion()}
        {renderPsp()}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <InputLabel
            shrink
            id="demo-simple-select-label"
            style={{ marginLeft: 16, fontWeight: 'bold', color: '#8992a3' }}
          >
            DATE RANGE
          </InputLabel>
          <DatePicker
            date={startDate}
            handleDateChange={(date: any) => setStartDate(date)}
            style={{ maxWidth: 160, width: 160 }}
          />
        </Box>
        <DatePicker
          date={endDate}
          handleDateChange={(date: any) => setEndDate(date)}
          style={{ marginLeft: 8, maxWidth: 160, width: 160 }}
        />
        <Button
          disabled={downloadSpinner}
          onClick={() => {
            callTogglePanel();
            toggleDownloadDialog(true);
          }}
          startIcon={
            downloadSpinner ? (
              <CircularProgress size="small" />
            ) : (
              <CloudDownload />
            )
          }
          style={{
            marginLeft: 8,
            alignSelf: 'center',
            marginTop: 10,
            backgroundColor: '#a6c1ff',
          }}
          variant="contained"
        >
          <FormattedMessage
            id="downloadReport"
            defaultMessage="Download Report"
          />
        </Button>
      </Box>
    );
  };

  const summary = () => {
    let {
      currency,
      poslog_total_amount,
      psp_recon_total_amount,
      psp_recon_total_net_amount,
      psp_fee_total_amount,
      psp_payout_total_amount,
      matched,
      show_mishipay_reimbursement = false,
      poslog_excluded_total_discount,
    }: any = data;
    if (type === 'poslog_recon') {
      return (
        <>
          <Box className={classes.textbox1}>
            <Typography variant="subtitle2" style={{ color: '#8f9bb3' }}>
              <FormattedMessage
                id="totalPoslogAmount"
                defaultMessage="Total Poslog Amt"
              />
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {appendCurrency(currency, poslog_total_amount)}
            </Typography>
          </Box>
          <Box className={classes.textbox1}>
            <Typography variant="subtitle2" style={{ color: '#8f9bb3' }}>
              <FormattedMessage
                id="totalReconciliationAmt"
                defaultMessage="Total Reconciliation Amt"
              />
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {appendCurrency(currency, psp_recon_total_amount)}
            </Typography>
          </Box>
          {show_mishipay_reimbursement && (
            <Box className={classes.textbox1}>
              <Typography variant="subtitle2" style={{ color: '#8f9bb3' }}>
                <FormattedMessage
                  id="mispiPromoReimb"
                  defaultMessage="MishiPay Promotion Reimbursement"
                />
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {appendCurrency(currency, poslog_excluded_total_discount)}
              </Typography>
            </Box>
          )}
          <Box className={classes.statusContainer}>{icon(matched)}</Box>
        </>
      );
    } else
      return (
        <>
          <Box className={classes.textbox1}>
            <Typography variant="subtitle2" style={{ color: '#8f9bb3' }}>
              <FormattedMessage
                id="reconciliationAmt"
                defaultMessage="Reconciliation Amt"
              />
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {appendCurrency(currency, psp_recon_total_amount) || 0}
            </Typography>
          </Box>
          <Box className={classes.textbox1}>
            <Typography variant="subtitle2" style={{ color: '#8f9bb3' }}>
              <FormattedMessage id="fees" defaultMessage="Fees" />
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              - {appendCurrency(currency, psp_fee_total_amount)|| 0}
            </Typography>
          </Box>
          <Box className={classes.textbox1}>
            <Typography variant="subtitle2" style={{ color: '#8f9bb3' }}>
              ={' '}
              <FormattedMessage
                id="expectedPayout"
                defaultMessage="Expected payout"
              />
            </Typography>
            <Typography style={{ fontWeight: 'bold', marginLeft: 12 }}>
              ( {appendCurrency(currency, psp_recon_total_net_amount) || 0} )
            </Typography>
          </Box>
          <Box className={classes.textbox1}>
            <Typography variant="subtitle2" style={{ color: '#8f9bb3' }}>
              ={' '}
              <FormattedMessage
                id="totalPayout"
                defaultMessage="Total payout"
              />
            </Typography>
            <Typography style={{ fontWeight: 'bold', marginLeft: 12 }}>
              {appendCurrency(currency, psp_payout_total_amount) || 0}
            </Typography>
          </Box>
          <Box className={classes.statusContainer}>{icon(matched)}</Box>
        </>
      );
  };

  const renderReportTable = () => {
    let {
      reports: reportData = [],
      currency,
      show_mishipay_reimbursement,
    } = data;

    let columns: any;
    switch (type) {
      case 'poslog_recon':
        // eslint-disable-next-line no-lone-blocks
        {
          columns = [
            {
              title: (
                <FormattedMessage id="storeId" defaultMessage="Store ID" />
              ),
              field: 'retailer_store_id',
            },
            {
              title: (
                <FormattedMessage id="poslogAmt" defaultMessage="Poslog Amt" />
              ),
              field: 'poslog_total_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.poslog_total_amount),
            },
            {
              title: (
                <FormattedMessage
                  id="reconAmt"
                  defaultMessage="Reconciliation Amt"
                />
              ),
              field: 'psp_recon_total_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.psp_recon_total_amount),
            },
            {
              title: (
                <FormattedMessage
                  id="mishiReimbursement"
                  defaultMessage="MishiPay Reimbursement"
                />
              ),
              field: 'poslog_excluded_total_discount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              hidden: !show_mishipay_reimbursement,
              render: (rowData: any) =>
                appendCurrency(
                  currency,
                  rowData?.poslog_excluded_total_discount,
                ),
            },
            {
              title: <FormattedMessage id="status" defaultMessage="Status" />,
              field: 'matched',
              align: 'left',
              render: (rowData: any) =>
                renderStatusIcon(rowData?.matched, 'small', null),
            },
            {
              title: <FormattedMessage id="notes" defaultMessage="Notes" />,
              field: 'notes',
              align: 'left',
            },
          ];
        }
        break;
      case 'payout_recon': {
        columns = [
          {
            title: (
              <FormattedMessage id="paidDate" defaultMessage="Paid Out Date" />
            ),
            field: 'date',
          },
          {
            title: (
              <FormattedMessage
                id="reconAmt"
                defaultMessage="Reconciliation Amt"
              />
            ),
            field: 'psp_recon_total_amount',
            align: 'left',
            cellStyle: {
              fontWeight: 'bold',
            },
            render: (rowData: any) =>
              appendCurrency(currency, rowData?.psp_recon_total_amount),
          },
          {
            title: <FormattedMessage id="fees" defaultMessage="Fees" />,
            field: 'psp_fee_total_amount',
            align: 'left',
            cellStyle: {
              fontWeight: 'bold',
            },
            render: (rowData: any) =>
              appendCurrency(currency, rowData?.psp_fee_total_amount),
          },
          {
            title: (
              <FormattedMessage
                id="expectedPayout"
                defaultMessage="Expected Payout Amt"
              />
            ),
            field: 'psp_recon_total_net_amount',
            align: 'left',
            cellStyle: {
              fontWeight: 'bold',
            },
            render: (rowData: any) =>
              appendCurrency(currency, rowData?.psp_recon_total_net_amount),
          },
          {
            title: (
              <FormattedMessage id="paidOut" defaultMessage="Paid Out Amount" />
            ),
            field: 'psp_payout_total_amount',
            align: 'left',
            cellStyle: {
              fontWeight: 'bold',
            },
            render: (rowData: any) =>
              appendCurrency(currency, rowData?.psp_payout_total_amount),
          },
          {
            title: <FormattedMessage id="status" defaultMessage="Status" />,
            field: 'matched',
            align: 'left',
            render: (rowData: any) =>
              renderStatusIcon(rowData?.matched, 'small', true),
          },
          {
            title: (
              <FormattedMessage
                id="payoutRefId"
                defaultMessage="Payout Ref Id"
              />
            ),
            field: 'notes',
            align: 'left',
          },
        ];
        break;
      }

      default:
        {
          columns = [];
        }
        break;
    }

    return (
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={columns}
        data={reportData}
        icons={tableIcons}
        components={{
          Toolbar: () => <></>,
        }}
        isLoading={isLoading}
        options={{
          paging: false,
          search: false,
          headerStyle: { backgroundColor: '#a6c1ff', height: '20px' },
          loadingType: 'overlay',
          detailPanelColumnAlignment: 'right',
          rowStyle: (rowData: any) => ({
            backgroundColor: rowData.matched ? 'white' : '#fff9f3',
            height: 66,
          }),
          tableLayout: 'fixed',
        }}
        style={{ marginLeft: 24, marginRight: 24 }}
        onRowClick={(event, row_data, togglePanel: any) => {
          event.stopPropagation();
          togglePanel();
        }}
        detailPanel={(rowData: any) =>
          renderDetailPanel(rowData, show_mishipay_reimbursement)
        }
      />
      // </ClickAwayListener>
    );
  };

  const callTogglePanel = () => {
    try {
      tableRef?.current?.['onToggleDetailPanel']?.([0], rowData => (
        <div>{rowData.name}</div>
      ));
    } catch (e) {}
  };

  React.useEffect(() => {
    callTogglePanel();
  });

  const renderDetailPanel = (
    rowData: any,
    show_mishipay_reimbursement: boolean = false,
  ) => {
    let { currency } = data;
    let columns: any;
    let detailPanelData =
      type === 'poslog_recon'
        ? rowData?.reports_date_wise
        : rowData?.reports_store_wise;

    switch (type) {
      case 'poslog_recon':
        // eslint-disable-next-line no-lone-blocks
        {
          columns = [
            {
              title: <FormattedMessage id="date" defaultMessage="Date" />,
              field: 'date',
            },
            {
              title: (
                <FormattedMessage id="poslogAmt" defaultMessage="Poslog Amt" />
              ),
              field: 'poslog_total_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.poslog_total_amount),
            },
            {
              title: (
                <FormattedMessage
                  id="reconAmt"
                  defaultMessage="Reconciliation Amt"
                />
              ),
              field: 'psp_recon_total_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.psp_recon_total_amount),
            },
            {
              title: (
                <FormattedMessage
                  id="mishiReimbursement"
                  defaultMessage="MishiPay Reimbursement"
                />
              ),
              field: 'poslog_excluded_total_discount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              hidden: !show_mishipay_reimbursement,
              render: (rowData: any) =>
                appendCurrency(
                  currency,
                  rowData?.poslog_excluded_total_discount,
                ),
            },
            {
              title: <FormattedMessage id="status" defaultMessage="Status" />,
              field: 'matched',
              align: 'left',
              render: (rowData: any) =>
                renderStatusIcon(rowData?.matched, 'small', null),
            },
            {
              title: <FormattedMessage id="notes" defaultMessage="Notes" />,
              field: 'notes',
              align: 'left',
            },
          ];
        }
        break;
      case 'payout_recon':
        // eslint-disable-next-line no-lone-blocks
        {
          columns = [
            {
              title: (
                <FormattedMessage id="storeId" defaultMessage="Store ID" />
              ),
              field: 'retailer_store_id',
            },
            {
              title: (
                <FormattedMessage
                  id="reconAmt"
                  defaultMessage="Reconciliation Amt"
                />
              ),
              field: 'psp_recon_total_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.psp_recon_total_amount),
            },
            {
              title: <FormattedMessage id="fees" defaultMessage="Fees" />,
              field: 'psp_fee_total_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.psp_fee_total_amount),
            },
            {
              title: (
                <FormattedMessage
                  id="expectedPayout"
                  defaultMessage="Expected payout"
                />
              ),
              field: 'psp_recon_total_net_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.psp_recon_total_net_amount),
            },
            {
              title: <FormattedMessage id="payout" defaultMessage="Payout" />,
              field: 'psp_payout_total_amount',
              align: 'left',
              cellStyle: {
                fontWeight: 'bold',
              },
              render: (rowData: any) =>
                appendCurrency(currency, rowData?.psp_payout_total_amount),
            },
            {
              title: ' ',
            },
            {
              title: ' ',
            },
          ];
        }
        break;
      default:
        {
          columns = [];
        }
        break;
    }

    return (
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: '#f2f6ff',
        }}
      >
        <ClickAwayListener onClickAway={callTogglePanel}>
          <div>
            <MaterialTable
              title=""
              columns={columns}
              data={detailPanelData}
              icons={tableIcons}
              components={{
                Toolbar: () => <></>,
              }}
              isLoading={isLoading}
              style={{
                marginTop: 8,
                marginBottom: 8,
                marginLeft: 24,
                marginRight: 24,
              }}
              options={{
                paging: false,
                search: false,
                // headerStyle: { height: 57 },
                detailPanelColumnAlignment: 'right',
                rowStyle: rowData => {
                  let color = 'white';
                  if (rowData.matched == true) {
                    color = 'white';
                  } else if (rowData.matched == false) {
                    color = '#fff9f3';
                  }
                  return {
                    backgroundColor: color,
                    height: 10,
                  };
                },
                tableLayout: 'fixed',
              }}
            />
          </div>
        </ClickAwayListener>
      </div>
    );
  };

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column',
        backgroundColor: '#e8ecf3',
        paddingBottom: 16,
      }}
    >
      <NavigationTopBar
        title={
          <FormattedMessage
            id="viewReport"
            defaultMessage="View Settlement Report"
          />
        }
        subtitle={
          type === 'poslog_recon' ? (
            <FormattedMessage
              id="posAndreconSettlement"
              defaultMessage="Poslog and reconciliation settlement"
            />
          ) : (
            <FormattedMessage
              id="payoutSummary"
              defaultMessage="Payout Summary"
            />
          )
        }
      />
      <Toolbar className={classes.sub_toolbar}>
        <>{filterComponent()}</>
      </Toolbar>
      {isLoading ? (
        <Box
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: 50,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            style={{
              backgroundColor: '#e8ecf3',
              alignSelf: 'flex-start',
              display: 'flex',
              paddingLeft: 20,
            }}
          >
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              <FormattedMessage id="total" defaultMessage="Total" />
            </Typography>
          </Box>
          <Box
            style={{
              backgroundColor: '#e8ecf3',
              maxHeight: 120,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 24,
              paddingRight: 24,
              minHeight: 80,
            }}
          >
            <>{summary()}</>
          </Box>
          {
            <Box style={{ backgroundColor: '#e8ecf3' }}>
              {renderReportTable()}
            </Box>
          }
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={toastVisible}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning">
          <FormattedMessage
            id="reportAlert"
            defaultMessage="Please select a date less than 60 days"
          />
        </Alert>
      </Snackbar>

      <Dialog
        disableEnforceFocus
        disableEscapeKeyDown
        open={downladDialogVisible}
        TransitionComponent={Transition}
        scroll="paper"
        onClose={() => toggleDownloadDialog(false)}
      >
        <Card style={{ maxWidth: 320 }}>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 24, marginLeft: 16, fontWeight: 'bold' }}
          >
            Download Report
          </Typography>
          <Box style={{ marginLeft: 16, marginBottom: 24 }}>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedDelimiter}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  changeDelimiter((event.target as HTMLInputElement).value);
                }}
              >
                {delimiters.map((item: any, index: number) => {
                  return (
                    <Box style={{ margin: 4 }}>
                      <FormControlLabel
                        style={{ fontWeight: 'bold' }}
                        value={item.name}
                        control={<Radio color="primary" />}
                        label={
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.name}
                          </Typography>
                        }
                      />
                      <Typography variant="body1" style={{ marginLeft: 30 }}>
                        Standard Format Column separator delimiter `
                        {item.column_delimiter}` and Amount decimal `
                        {item.amount_delimiter}`
                      </Typography>
                    </Box>
                  );
                })}
              </RadioGroup>
            </FormControl>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 8,
                marginTop: 16,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => downloadReport()}
              >
                OK
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: 8 }}
                onClick={() => {
                  toggleDownloadDialog(false);
                }}
              >
                CANCEL
              </Button>
            </Box>
          </Box>
        </Card>
      </Dialog>
    </Box>
  );
};

export default SettlementReports;
