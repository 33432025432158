import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const Chart10 = ({ data: chartData, title, total, size }: any) => {
  let values = chartData.map((item: any) => item?.value);
  let backgroundColors = chartData.map((item: any) => item?.color);
  let labels = chartData.map((item: any) => item.title);
  const data = {
    title: title,
    data: values,
    labels: labels,
    backgroundColor: backgroundColors,
    borderWidth: 1,
  };

  const series = data.data;
  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter, sans-serif',
      type: 'donut',
      width: 380,
    },
    colors: data.backgroundColor,
    labels: data.labels,
    legend: {
      show: false,
      position: 'bottom',
    },

    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div
      className="w-full rounded-lg bg-white pb-5 p-[30px] sm:px-[30px]"
      style={{ boxShadow: '0px 1px 3px 0px rgba(166, 175, 195, 0.40)' }}
    >
      <div className="sm:mb-0">
        <h5 className="text-left text-dark text-[20px] font-[600] mb-[10px] mt-[28px]">
          {data.title}
        </h5>
      </div>
      <div className="mb-2">
        <div id="chartOne" className="chart-10 mx-auto flex justify-center">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width={380}
          />
        </div>
      </div>
      <div className="-mx-8 flex flex-wrap items-center">
        {data.labels.map((itemLabel: any, index: number) => (
          <AnalyticsItem
            title={itemLabel}
            percent={data.data[index]}
            color={data.backgroundColor[index]}
          />
        ))}
      </div>
    </div>
  );
};

export const Chart = React.memo(Chart10);

const AnalyticsItem = ({ title, percent, color }) => {
  return (
    <div className="mb-3 w-full px-8">
      <div className="flex w-full items-center">
        <span
          className={`mr-2 block h-3 w-full max-w-[12px] rounded-full`}
          style={{ backgroundColor: color }}
        ></span>
        <p className="flex w-full justify-between text-left text-sm text-dark dark:text-white mb-0">
          <span> {title} </span>
          <span> {percent} </span>
        </p>
      </div>
    </div>
  );
};
