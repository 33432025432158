import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Hidden,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NavigationTopBar } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import MishipayBackground from '../../assets/new-bg.svg';
import MishipayLogo from '../../assets/mishipay-logo.png';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { resetPasswordApi } from 'src/services/user';

const useStyles = makeStyles((theme: any) => ({
  paperContainer: {
    width: '100vw',
    height: '100vh',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${MishipayBackground})`,
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    objectFit: 'contain',
  },
  innerContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  textField: {
    width: '100%',
    maxWidth: '343px',
    marginTop: 20,
  },

  logoContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    objectFit: 'contain',
    height: '60px',
  },

  innerContainer1: {
    display: 'flex',
    flex: 0.3,
  },

  innerContainer2: {
    display: 'flex',
    flex: 0.7,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  buttonContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
    marginTop: 20,
  },

  buttonLogin: {
    flex: 1,
    height: '40px',
    color: 'white',
    fontWeight: 'bold',
  },

  forgotPassword: {
    color: theme.palette.color_dark_text.main,
    textAlign: 'left',
    marginLeft: 32,
    marginRight: 32,
  },

  login: {
    color: theme.palette.color_dark_text.main,
  },

  loginLink: { textDecoration: 'none', display: 'flex', marginTop: 20 },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    top: 90,
    display: 'flex',
    justifyContent: 'center',
  },

  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const ResetPassword = () => {
  const history = useHistory();
  const code =
    history.location.search.indexOf('=') !== -1
      ? history.location.search.split('=')[1]
      : null;
  const classes = useStyles();
  const intl = useIntl();
  const [inputs, setInputs] = useState({
    code,
    password1: '',
    password2: '',
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    let timer: any = null;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
        history.replace('/');
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  const handleInputChange = (e: any) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    reset();
  };

  const resetPassword = (formData: any) =>
    resetPasswordApi.create({}, JSON.stringify(formData));

  const { isLoading, mutate, reset } = useMutation(resetPassword, {
    onError: (serverError: any) => {
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: async successData => {
      setSuccess(true);
    },
  });

  const handleLogin = () => {
    if (inputs.password1.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyNewPassword' }));
    } else if (inputs.password1.length < 5) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'invalidNewPassword' }));
    } else if (inputs.password2.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyConfirmPassword' }));
    } else if (inputs.password2.length < 5) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'invalidConfirmPassword' }));
    } else if (inputs.password1 !== inputs.password2) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'differentPasswords' }));
    } else if (code === null) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'resetCodeNotAvailable' }));
    } else {
      mutate(inputs);
    }
  };

  return (
    <Paper className={classes.paperContainer}>
      <Hidden only={['xs']} implementation="css">
        {isLoading && <LinearProgress color="primary" />}
      </Hidden>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}

      {success && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="success"
          >
            {intl.formatMessage({ id: 'resetPasswordSuccess' })}
          </Alert>
        </Box>
      )}
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          <NavigationTopBar
            titlekey="resetPassword"
            defaultValue="Reset password"
            arrowPresent={false}
          />
          <Box className={classes.innerContainer1}>
            <Box component="div" className={classes.logoContainer}>
              <img
                src={MishipayLogo}
                alt="Mishipay Logo"
                className={classes.logo}
              />
            </Box>
          </Box>
          <Typography className={classes.forgotPassword}>
            {intl.formatMessage({ id: 'resetPasswordTitle' })}
          </Typography>
          <Box className={classes.innerContainer2}>
            <TextField
              className={classes.textField}
              name="password1"
              type="password"
              placeholder={intl.formatMessage({ id: 'newPassword' })}
              onChange={handleInputChange}
            />

            <TextField
              className={classes.textField}
              name="password2"
              type="password"
              placeholder={intl.formatMessage({ id: 'confirmPassword' })}
              onChange={handleInputChange}
            />

            <Box className={classes.buttonContainer}>
              <Button
                className={classes.buttonLogin}
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={isLoading}
              >
                <FormattedMessage
                  id="reset"
                  defaultMessage="RESET"
                ></FormattedMessage>
              </Button>
            </Box>
          </Box>
        </Box>
        <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
          {isLoading && (
            <Box className={classes.mobileLoaderContainer}>
              <Box className={classes.mobileLoader}>
                <CircularProgress
                  className={classes.circle}
                  size={25}
                  thickness={5}
                ></CircularProgress>
                <Typography>Loading...</Typography>
              </Box>
            </Box>
          )}
        </Hidden>
      </Box>
    </Paper>
  );
};

export default ResetPassword;
