import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function FullyRefundedIcon(props: any) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Layer 2">
        <path
          d="M18.78 14.38a1 1 0 00-1.4-.16L14 16.92V8a3 3 0 00-3-3H6a1 1 0 000 2h5a1 1 0 011 1v8.92l-3.38-2.7a1 1 0 00-1.24 1.56l5 4a1 1 0 001.24 0l5-4a1 1 0 00.16-1.4z"
          data-name="corner-right-down"
        />
      </g>
    </SvgIcon>
  );
}

export default FullyRefundedIcon;
