import React from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import storage from 'redux-persist/lib/storage';
import { setTimeout } from 'timers';

export const LogoutContext = React.createContext<any>({
  logoutUser: () => {},
});

const LogoutWrapper = ({ children, persistor }: any) => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();

  const logoutUser = () => {
    let currentURL = window.location.href.split('//')[1].split('/')[0];
    if (currentURL.includes(':')) {
      currentURL = currentURL.split(':')[0];
    }
    dispatch({
      type: 'LOGOUT_USER',
    });
    persistor.purge().then(() => {
      localStorage.clear();
      removeCookie('app_token', {
        path: '/',
        domain: currentURL,
      });
    });
  };

  return (
    <LogoutContext.Provider value={{ logoutUser }}>
      {children}
    </LogoutContext.Provider>
  );
};

export default LogoutWrapper;
