import { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UKFlag from '../../assets/uk_flag.svg';
import FRFlag from '../../assets/fr_flag.svg';
import ESFlag from '../../assets/es_flag.svg';
import {  useIntl } from 'react-intl';
import MishipayBackground from '../../assets/new-bg.svg';
import MishipayLogo from '../../assets/mishipay-logo.png';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { signUpApi } from 'src/services/user';
import { LanguageContext } from 'src/components/LanguageProvider';

const languages = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  no: 'Norwegian',
};
const flags = {
  en: UKFlag,
  es: ESFlag,
  fr: FRFlag,
  no: ESFlag,
};
const menuItems = [
  {
    title: 'English',
    value: 'en',
    flag: UKFlag,
  },
  {
    title: 'Spanish',
    value: 'es',
    flag: ESFlag,
  },
  {
    title: 'French',
    value: 'fr',
    flag: FRFlag,
  },
  {
    title: 'Norwegian',
    value: 'no',
    flag: ESFlag,
  },
];
const useStyles = makeStyles((theme: any) => ({
  paperContainer: {
    width: '100vw',
    height: '100vh',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${MishipayBackground})`,
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    objectFit: 'contain',
  },
  innerContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  textField: {
    width: '100%',
    maxWidth: '343px',
    marginTop: 20,
  },

  logoContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    objectFit: 'contain',
    height: '60px',
  },

  innerContainer1: {
    display: 'flex',
    flex: 0.3,
  },

  innerContainer2: {
    display: 'flex',
    flex: 0.7,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  buttonContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
    marginTop: 20,
  },

  buttonLogin: {
    flex: 1,
    height: '40px',
    color: 'white',
    fontWeight: 'bold',
  },

  login: {
    color: theme.palette.color_dark_text.main,
  },

  loginLink: { textDecoration: 'none', display: 'flex', marginTop: 20 },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    top: 90,
    display: 'flex',
    justifyContent: 'center',
  },

  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const classes = {
  inputError:
    'w-full bg-transparent rounded-md border-red py-[10px] pl-5 pr-12 text-dark-6 outline-none transition',
  inputNormal:
    'w-full rounded-md border border-[#E9EDF4] bg-transparent px-3 py-[10px] text-body-color outline-none focus:border-primary focus-visible:shadow-none dark:border-dark-3 dark:text-dark',
};

const Signup = () => {
  const context = useContext(LanguageContext);
  // const classes = useStyles();
  const intl = useIntl();
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    email: '',
    token: '',
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const [storeLoading, setStoreLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // let useClickOutside = handler => {
  //   let domNode = useRef();
  //   useEffect(() => {
  //     let maybeHandler = event => {
  //       if (!domNode?.current?.contains(event.target)) {
  //         handler();
  //       }
  //     };
  //     document.addEventListener('mousedown', maybeHandler);
  //     return () => {
  //       document.removeEventListener('mousedown', maybeHandler);
  //     };
  //   });

  //   return domNode;
  // };

  // let domNode = useClickOutside(() => {
  //   setDropdownOpen(false);
  // });

  useEffect(() => {
    let timer: any = null;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
        history.goBack();
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  const handleInputChange = (e: any) => {
    setError(false);
    setErrorMessage('Error');
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    reset();
  };

  const signUpUser = (formData: any) => {
    return signUpApi.create({}, JSON.stringify(formData));
  };

  const { isLoading, mutate, reset } = useMutation(signUpUser, {
    onError: (serverError: any) => {
      setError(true);
      setErrorMessage(serverError);
      setStoreLoading(false);
    },
    onSuccess: async successData => {
      console.log('Success Data', successData);
      setSuccess(true);
      setStoreLoading(false);
    },
  });

  const handleLogin = () => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (inputs.username.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyUsername' }));
    } else if (inputs.token.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyToken' }));
    } else if (inputs.email.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyEmail' }));
    } else if (!re.test(inputs.email.toLowerCase())) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'invalidEmail' }));
    } else if (inputs.password.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyPassword' }));
    } else if (inputs.password.length < 5) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'passwordLength' }));
    } else {
      mutate(inputs);
    }
    console.log(inputs);
  };
  const DropdownItem = ({ label, value, img }) => {
    return (
      <div
        className="inline-flex py-2 pl-5 text-left text-base text-[#637381] no-underline hover:bg-slate-200 w-[100%!important]"
        onClick={() => {
          context.changeLanguage(value);
          setDropdownOpen(!dropdownOpen);
        }}
      >
        {label}
        <span className="ml-auto my-auto mr-[10px]">
          <img src={img} alt={label} />
        </span>
      </div>
    );
  };
  return (
    <section
      className="bg-tg-bg py-20 dark:bg-whitesmoke lg:py-[80px]"
      // ref={domNode}
    >
      <div className="container mx-auto">
        <div className="bg-white dark:bg-dark-2">
          <div className="flex flex-wrap items-stretch">
            <div className="w-full lg:w-1/2">
              <div className="w-full px-6 py-14 sm:p-[70px] sm:px-12 xl:px-[90px]">
                <h2 className="mb-10 text-[32px] font-bold text-dark dark:text-white text-left">
                  Sign Up
                </h2>
                <form onSubmit={handleLogin} target="_self">
                  <div className="mb-8 inline-flex w-[100%]">
                    <div className="w-[50%]">
                      <label className="mb-2.5 block text-[16px] text-dark dark:text-white text-left">
                        {intl.formatMessage({ id: 'username' })}
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="username"
                          onChange={handleInputChange}
                          className={
                            error ? classes.inputError : classes.inputNormal
                          }
                        />
                        {error && (
                          <span className="absolute top-1/2 right-8 -translate-y-1/2">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                                fill="#DC3545"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                                fill="#DC3545"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                                fill="#DC3545"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="ml-[5%] w-[50%]">
                      <label className="mb-2.5 block text-[16px] text-dark dark:text-white text-left">
                        {intl.formatMessage({ id: 'token' })}
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="token"
                          onChange={handleInputChange}
                          className={
                            error ? classes.inputError : classes.inputNormal
                          }
                        />
                        {error && (
                          <span className="absolute top-1/2 right-4 -translate-y-1/2">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                                fill="#DC3545"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                                fill="#DC3545"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                                fill="#DC3545"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-8">
                    <label className="mb-2.5 block text-[16px] text-dark dark:text-white text-left">
                      {intl.formatMessage({ id: 'email' })}
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        name="email"
                        onChange={handleInputChange}
                        className={
                          error ? classes.inputError : classes.inputNormal
                        }
                      />
                      {error && (
                        <span className="absolute top-1/2 right-4 -translate-y-1/2">
                          <svg
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                              fill="#DC3545"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mb-8">
                    <label className="mb-2.5 block text-[16px] text-dark dark:text-white text-left">
                      {intl.formatMessage({ id: 'password' })}
                    </label>
                    <div className="relative">
                      <input
                        type="password"
                        name="password"
                        onChange={handleInputChange}
                        onKeyPress={(e: any) => {
                          if (e.key === 'Enter') {
                            handleLogin();
                          }
                        }}
                        className={
                          error ? classes.inputError : classes.inputNormal
                        }
                      />
                      {error && (
                        <span className="absolute top-1/2 right-4 -translate-y-1/2">
                          <svg
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                              fill="#DC3545"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                    <p
                      className="mt-[5px] text-sm text-red text-left"
                      style={{ visibility: error ? 'visible' : 'hidden' }}
                    >
                      {errorMessage}
                    </p>
                  </div>
                  <button
                    onClick={handleLogin}
                    disabled={isLoading || storeLoading}
                    className="mb-8 w-full inline-flex h-12 items-center justify-center gap-2.5 cursor-pointer rounded-md bg-primary px-[14px] py-3 text-base font-medium text-white transition hover:bg-opacity-90"
                  >
                    <span>
                      {(isLoading || storeLoading) && (
                        <svg
                          className="animate-spin"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            opacity="0.5"
                            cx="10"
                            cy="10"
                            r="9"
                            stroke="white"
                            stroke-width="2"
                          />
                          <mask id="path-2-inside-1_2527_20936" fill="white">
                            <path d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z" />
                          </mask>
                          <path
                            d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z"
                            stroke="white"
                            stroke-width="4"
                            mask="url(#path-2-inside-1_2527_20936)"
                          />
                        </svg>
                      )}
                    </span>
                    {isLoading || storeLoading
                      ? 'Loading...'
                      : intl.formatMessage({ id: 'signUp' })}
                  </button>
                </form>
                <div className="flex flex-wrap justify-between">
                  <p className="mb-2 text-base text-dark dark:text-dark-6">
                    <span className="pr-0.5"> Already have an account? </span>

                    <a href="/" className="text-primary hover:underline">
                      {intl.formatMessage({ id: 'login' })}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="relative h-full w-full overflow-hidden bg-[#F59E0B]">
                <div
                  className="relative inline-block text-left z-50"
                  style={{ top: '5%', left: '25%' }}
                >
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className={`flex items-center rounded-[6px] pl-[15px] py-[8px] bg-white text-base font-medium text-[#637381] w-[150px]`}
                  >
                    {languages[context.locale]}
                    <span className="ml-auto my-auto mr-[10px]">
                      <img src={flags[context.locale]} alt={context.locale} />
                    </span>
                  </button>
                  <div
                    className={`shadow-1 dark:shadow-box-dark absolute left-0 z-40 mt-2 w-full rounded-md bg-white py-[10px] transition-all ${
                      dropdownOpen
                        ? 'top-full opacity-100 visible'
                        : 'top-[110%] invisible opacity-0'
                    }`}
                  >
                    {menuItems.map((item: any) => (
                      <DropdownItem
                        label={item.title}
                        value={item.value}
                        img={item.flag}
                      />
                    ))}
                  </div>
                </div>
                <div className="flex h-full items-end p-8 sm:p-14">
                  <h3 className="text-[28px]/[34px] font-bold text-white text-left mb-20">
                    Hey <br />
                    Welcome <br />
                    Back
                  </h3>
                  <div>
                    <span className="absolute left-0 top-0">
                      <svg
                        width="415"
                        height="355"
                        viewBox="0 0 415 355"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M415 107.5C415 244.19 304.19 355 167.5 355C30.8095 355 -80 244.19 -80 107.5C-80 -29.1905 30.8095 -140 167.5 -140C304.19 -140 415 -29.1905 415 107.5Z"
                          fill="url(#paint0_linear_1179_8)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1179_8"
                            x1="167.5"
                            y1="-140"
                            x2="167.5"
                            y2="355"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0.177083"
                              stopColor="white"
                              stopOpacity="0.16"
                            />
                            <stop
                              offset="1"
                              stopColor="white"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute left-0 top-0">
                      <svg
                        width="177"
                        height="354"
                        viewBox="0 0 177 354"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M177 177C177 274.754 97.7544 354 0 354C-97.7544 354 -177 274.754 -177 177C-177 79.2456 -97.7544 0 0 0C97.7544 0 177 79.2456 177 177Z"
                          fill="url(#paint0_linear_1179_7)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1179_7"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="354"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0.177083"
                              stopColor="white"
                              stopOpacity="0.2"
                            />
                            <stop
                              offset="1"
                              stopColor="white"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute bottom-16 right-20">
                      <svg
                        width="85"
                        height="85"
                        viewBox="0 0 85 85"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M42.5 -1.85773e-06C65.9721 -2.88373e-06 85 19.0279 85 42.5C85 65.9721 65.9721 85 42.5 85C19.0279 85 -8.31736e-07 65.9721 -1.85773e-06 42.5C-2.88373e-06 19.0279 19.0279 -8.31736e-07 42.5 -1.85773e-06Z"
                          fill="url(#paint0_linear_1179_6)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1179_6"
                            x1="-1.85774e-06"
                            y1="42.5"
                            x2="85"
                            y2="42.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0.177083"
                              stopColor="white"
                              stopOpacity="0.16"
                            />
                            <stop
                              offset="1"
                              stopColor="white"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  // return (
  //   <Paper className={classes.paperContainer}>
  //     <Hidden only={['xs']} implementation="css">
  //       {isLoading && <LinearProgress color="primary" />}
  //     </Hidden>
  //     {error && (
  //       <Box className={classes.errorMessageContainer}>
  //         <Alert
  //           className={classes.errorMessage}
  //           variant="filled"
  //           severity="error"
  //         >
  //           {errorMessage}
  //         </Alert>
  //       </Box>
  //     )}

  //     {success && (
  //       <Box className={classes.errorMessageContainer}>
  //         <Alert
  //           className={classes.errorMessage}
  //           variant="filled"
  //           severity="success"
  //         >
  //           Signup Successfull!. Please login.
  //         </Alert>
  //       </Box>
  //     )}
  //     <Box className={classes.container}>
  //       <Box className={classes.innerContainer}>
  //         <NavigationTopBar titlekey="signUp" defaultValue="Sign up" />
  //         <Box className={classes.innerContainer1}>
  //           <Box component="div" className={classes.logoContainer}>
  //             <img
  //               src={MishipayLogo}
  //               alt="Mishipay Logo"
  //               className={classes.logo}
  //             />
  //           </Box>
  //         </Box>
  //         <Box className={classes.innerContainer2}>
  //           <TextField
  //             className={classes.textField}
  //             name="username"
  //             placeholder={intl.formatMessage({ id: 'username' })}
  //             onChange={handleInputChange}
  //           />

  //           <TextField
  //             className={classes.textField}
  //             name="password"
  //             placeholder={intl.formatMessage({ id: 'password' })}
  //             type="password"
  //             onChange={handleInputChange}
  //           />

  //           <TextField
  //             className={classes.textField}
  //             name="email"
  //             placeholder={intl.formatMessage({ id: 'email' })}
  //             onChange={handleInputChange}
  //           />

  //           <TextField
  //             className={classes.textField}
  //             name="token"
  //             placeholder={intl.formatMessage({ id: 'token' })}
  //             onChange={handleInputChange}
  //           />

  //           <Box className={classes.buttonContainer}>
  //             <Button
  //               className={classes.buttonLogin}
  //               variant="contained"
  //               color="primary"
  //               onClick={handleLogin}
  //               disabled={isLoading}
  //             >
  //               <FormattedMessage
  //                 id="signUp"
  //                 defaultMessage="SIGN UP"
  //               ></FormattedMessage>
  //             </Button>
  //           </Box>

  //           <Link to="/" className={classes.loginLink} replace>
  //             <Typography className={classes.login}>
  //               {intl.formatMessage({ id: 'haveAnAccount' })}
  //             </Typography>
  //             <Typography className={classes.login}>
  //               <strong>{intl.formatMessage({ id: 'login' })}</strong>
  //             </Typography>
  //           </Link>
  //         </Box>
  //       </Box>
  //       <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
  //         {isLoading && (
  //           <Box className={classes.mobileLoaderContainer}>
  //             <Box className={classes.mobileLoader}>
  //               <CircularProgress
  //                 className={classes.circle}
  //                 size={25}
  //                 thickness={5}
  //               ></CircularProgress>
  //               <Typography>Loading...</Typography>
  //             </Box>
  //           </Box>
  //         )}
  //       </Hidden>
  //     </Box>
  //   </Paper>
  // );
};

export default Signup;
