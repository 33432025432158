import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import PublicRoute from '../PublicRoute';
import PrivateRoute from '../PrivateRoute';
import {
  ForgotPassword,
  Home,
  Login,
  OrderDetails,
  ResetPassword,
  Signup,
  SettlementReports,
  VerificationDetails,
} from 'src/screens';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute restricted={true} component={Login} path="/" exact />
        <PublicRoute
          restricted={true}
          component={Signup}
          path="/signup"
          exact
        />
        <PublicRoute
          restricted={true}
          component={ForgotPassword}
          path="/forgot-password"
          exact
        />
        <PublicRoute
          restricted={false}
          component={ResetPassword}
          path="/reset-password"
          exact
        />
        <PrivateRoute component={Home} path="/dashboard" />
        <PrivateRoute
          component={OrderDetails}
          path="/orderDetails/:order_id"
          exact
        />
        <PrivateRoute
          component={VerificationDetails}
          path="/verificationDetails"
          exact
        />
        <PrivateRoute
          component={SettlementReports}
          path="/reportDetails/:type"
          exact
        />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
