// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { subscriber, messageService } from './messageService';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBQ5iTBkin6f-obYY91O0mW87C_4ipFlRc',
  authDomain: 'mishipay-dashboard.firebaseapp.com',
  databaseURL: 'https://mishipay-dashboard.firebaseio.com',
  projectId: 'mishipay-dashboard',
  storageBucket: 'mishipay-dashboard.appspot.com',
  messagingSenderId: '500045390163',
  appId: '1:500045390163:web:a9671912517e53aaf501a6',
  measurementId: 'G-CPYF3EC9GJ',
};
const testfirebaseConfig = {
  apiKey: 'AIzaSyBXvAHXJkyRaR4tpAQvg5Ma3bxgwkzXjjY',
  authDomain: 'mishipay-dashboard-test.firebaseapp.com',
  projectId: 'mishipay-dashboard-test',
  storageBucket: 'mishipay-dashboard-test.appspot.com',
  messagingSenderId: '840775837087',
  appId: '1:840775837087:web:bf9f8a57cc4347c83d09aa',
  measurementId: 'G-9FRQ93B1BS',
};
// Initialize Firebase
const isTest = false;
const app = isTest
  ? initializeApp(testfirebaseConfig)
  : initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const vapidKey = isTest
  ? 'BHXd7aEKBa5uk3W-5eie4XwnOhSrBis51vvV0rE9uIASqQ0wxFaZ5PJKRXMQvN5olMcT6963LRiVEhK2U7CAuJ0'
  : 'BFEqPNPbLVhwb9a2ygxXTXujOBQDL7_UC6OcnPxvqt6nQje0bxbdymowj1pMPL1tC50gcBRhPy2DgqyWcHocS28';
const messaging = getMessaging();
let count = 0;

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then(currentToken => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        sessionStorage.setItem('fcm', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      retryReq();
    });
};

const retryReq = () => {
  count++;
  if (count < 2) {
    setTimeout(() => {
      requestForToken();
    }, 100);
  }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      console.log('payload', payload);
      // subscriber.next(payload);
      resolve(payload.notification);
    });
  });
