import React from 'react';
import { Paper, Box, IconButton, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '../../assets/refresh.svg';
import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import RefreshSvgComponentIcon from 'src/assets/svgIcons/RefreshSvgCompontIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.color_white.main,
    borderBottom: `1px solid var(--Stroke, #DFE4EA);`,
    borderRadius: 0,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '30px',
  },

  backArrow: {
    color: theme.palette.color_black.main,
  },

  title: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 600,
    fontSize: 24,
  },

  titleMargin: {
    marginLeft: 20,
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.color_light_grey.main,
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 10,
  },
  buttonColor: {
    background: '#ffaa00',
    '&:hover': {
      backgroundColor: (props: any) => `#c68400`,
    },
  },
  buttonPrint: {
    boxShadow: 'none',
    marginRight: 20,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 4,
    borderWidth: 1,
  },
  buttonRefresh: {
    marginRight: 20,
    backgroundColor: '#FFF',
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
  },
  buttonRefreshRipple: {
    backgroundColor: 'transparent',
  },
  icon: { width: 20, height: 20 },
}));

const NavigationTopBar = ({
  title = null,
  titlekey = null,
  defaultValue = null,
  arrowPresent = true,
  subtitle = null,
  refresh = false,
  printReceipt = false,
  onRefreshClick = null,
  onPrintReceiptClick = null,
  onEmailReceiptClick = null,
  rightComponent = () => <></>,
}: any) => {
  const theme: any = useTheme();
  const width = window.innerWidth < theme.breakpoints.values['sm'] ? 40 : 100;
  const classes = useStyles({ b1bg: 'info', width });
  const history = useHistory();
  let newClass = !arrowPresent
    ? clsx(classes.textContainer, classes.titleMargin)
    : classes.textContainer;

  return (
    <Paper className={classes.root}>
      <Box className={classes.container}>
        {arrowPresent && (
          <IconButton onClick={history.goBack}>
            <ArrowBackIcon className={classes.backArrow} />
          </IconButton>
        )}
        <Box className={newClass}>
          <Typography className={classes.title}>
            {titlekey && defaultValue && (
              <FormattedMessage id={titlekey} defaultMessage={defaultValue} />
            )}
            {title ? title : null}
          </Typography>
          {subtitle && (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          )}
        </Box>

        {printReceipt && (
          <Button
            className={`${classes.buttonPrint} ${classes.buttonColor} bg-primary text-white`}
            // className={classes.buttonPrint}
            variant="contained"
            onClick={onEmailReceiptClick}
          >
            <FormattedMessage
              id="emailReceipt"
              defaultMessage="Email Receipt"
            />
          </Button>
        )}
        {printReceipt && (
          <Button
            className={`${classes.buttonPrint} border-solid border-[#3758F9] text-primary bg-transparent`}
            variant="contained"
            onClick={onPrintReceiptClick}
          >
            <FormattedMessage
              id="printReceipt"
              defaultMessage="PRINT RECEIPT"
            />
          </Button>
        )}
        {refresh && width > 40 && (
          <Button
            className={classes.buttonRefresh}
            variant="outlined"
            onClick={onRefreshClick}
            startIcon={<RefreshSvgComponentIcon />}
            TouchRippleProps={{
              classes: { root: classes.buttonRefreshRipple },
            }}
          >
            {/* <FormattedMessage id="refresh" defaultMessage="REFRESH" /> */}
          </Button>
        )}
        {refresh && width === 40 && (
          <IconButton
            onClick={onRefreshClick}
            className={classes.buttonRefresh}
            TouchRippleProps={{
              classes: { root: classes.buttonRefreshRipple },
            }}
          >
            <img className={classes.icon} src={RefreshIcon} alt="refresh" />
          </IconButton>
        )}
        {rightComponent()}
      </Box>
    </Paper>
  );
};

export default NavigationTopBar;
