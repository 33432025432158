import { useIntl } from 'react-intl';
import { Box, Button, TextareaAutosize, Typography } from '@material-ui/core';
import ChatCenteredTextIcon from '../../../../assets/svgIcons/ChatCenteredTextIcon';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const OrderNote = (props: any) => {
  const intl = useIntl();
  const classes = useStyles();
  let { old_note, addNoteHandler } = props;
  const [inputData, setInputData] = useState({
    isValid: false,
    note: '',
  });
  useEffect(() => {
    if (old_note) {
      setInputData({ isValid: true, note: old_note });
    }
  }, [old_note]);

  return (
    <Box
      className={classes.promotedLayout}
      style={{ border: `solid 1px #E0E0E0` }}
    >
      <Box
        style={{
          display: 'flex',
          backgroundColor: '#E0E0E0',
          padding: 8,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography style={{ color: 'black', textAlign: 'left' }}>
          {intl.formatMessage({ id: 'orderNote' })}
        </Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextareaAutosize
          value={inputData.note}
          minRows={2}
          placeholder={intl.formatMessage({
            id: 'saveNotePlaceHolder',
          })}
          onChange={event => {
            setInputData({
              note: event.target.value,
              isValid: !!event.target.value,
            });
          }}
          style={{ flex: 1, border: 'none', padding: 16 }}
        />
        <Button
          disabled={old_note ? inputData.note === old_note : !inputData.isValid}
          startIcon={
            <ChatCenteredTextIcon
              color={
                (old_note ? inputData.note === old_note : !inputData.isValid)
                  ? '#E0E0E0'
                  : '#3366ff'
              }
            />
          }
          className={classes.saveNoteShow}
          variant='text'
          size='large'
          style={{
            marginRight: 10,
            marginLeft: 10,
          }}
          onClick={() => addNoteHandler(inputData.note)}
        >
          {intl.formatMessage({ id: 'saveNote' })}
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  promotedLayout: {
    marginTop: '8px',
  },
  saveNoteShow: {
    '&:disabled': {
      color: '#E0E0E0',
    },
    color: '#3366ff',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
  },
}));

export default OrderNote;
