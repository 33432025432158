import { Box } from '@material-ui/core';
import React from 'react';
import { NavigationTopBar } from 'src/components';

const HelpDesk = () => {
  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <NavigationTopBar titlekey="helpDesk" defaultValue="Help / Support" />
      <iframe
        title="Help / Support"
        src="https://mishipay.atlassian.net/servicedesk/customer/portals"
        sandbox="allow-scripts"
      />
    </Box>
  );
};

export default HelpDesk;
