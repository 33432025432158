import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Paper, Typography, Box, Divider } from '@material-ui/core';

const BarChart = (props: any) => {
  let { title, data: chartData } = props;
  let { barData, textData } = chartData;
  let modifyBarData = [...barData];
  modifyBarData.shift();

  let values = modifyBarData.map((item: any) => item?.value);
  let backgroundColors = modifyBarData.map((item: any) => '#96b4f0');
  let labels = modifyBarData.map((item: any, index: number) => index + 1);
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Paper
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 16,
        minHeight: 400,
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 'bold',
          alignSelf: 'flex-start',
        }}
      >
        {title}
      </Typography>
      <Box
        style={{
          padding: 8,
          marginTop: 16,
        }}
      >
        {values && values.length ? (
          <>
            <Bar
              data={data}
              height={240}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                responsive: true,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      lineWidth: 0,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                      lineWidth: 0,
                    },
                  },
                },
              }}
            />
            <Divider style={{ marginTop: 16 }} />

            <Box
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                paddingTop: 16,
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              <Typography style={{ color: '#8f9bb3' }}>
                {textData.title}
              </Typography>
              <Typography
                style={{ color: 'black', fontSize: 24, fontWeight: 'bold' }}
              >
                {textData.value}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Box>
    </Paper>
  );
};

export default BarChart;
