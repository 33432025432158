import resource from '../resource';

//create ,get ,query ,remove & update
export const ordersApi = resource('/order/list/');
export const orderDetailApi = resource('/order/scan');
export const orderDetailApiV2 = resource('dashboard-order/details');
export const refundOrder = resource('/refund-order/');
export const promotedOrderApi = resource('/refund-order-new');
export const refundOrderNew = resource('/refund-order-new/');
export const orderStatusApi = resource('/update-order-status/');
export const allOrders = resource('order/list/all-stores/');
export const serialNumberApi = resource('/basket-item-serial-number/');
export const saveOrderNoteApi = resource('/order-notes/');
export const resendOrderReceipt = resource('/dashboard-email/');
