import resource from '../resource';

//create ,get ,query ,remove & update
export const settlementSummaryApi = resource(
  '/settlement_summary_all_regions/',
);
export const poslogReportApi = resource(
  '/settlement_poslog_recon_report_by_region/',
);
export const payoutReportApi = resource(
  'settlement_recon_payout_report_by_region_and_psp/',
);

export const downloadPoslogReportsApi = resource(
  '/download_poslog_recon_report_by_region/',
);

export const downloadPayoutReportsApi = resource(
  '/download_settlement_recon_payout_report_by_region_and_psp/',
);
