//@ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import UKFlag from '../../assets/uk_flag.svg';
import FRFlag from '../../assets/fr_flag.svg';
import ESFlag from '../../assets/es_flag.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-query';
import { signin, storesApi, registerFCMTokenApi } from 'src/services/user';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import { computeStores, getRequiredStores } from 'src/utils';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext } from 'react';
import { LanguageContext } from 'src/components/LanguageProvider';
import { startCase, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { datadogLogs } from '@datadog/browser-logs';

const languages = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  no: 'Norwegian',
};
const flags = {
  en: UKFlag,
  es: ESFlag,
  fr: FRFlag,
  no: ESFlag,
};
const useStyles = makeStyles((theme: any) => ({
  multiSelect: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
    position: 'absolute',
    height: '48px',
    bottom: 50,
  },
}));

const classes = {
  inputError:
    'w-full bg-transparent rounded-md border-red py-[10px] pl-5 pr-12 text-dark-6 outline-none transition',
  inputNormal:
    'w-full rounded-md border border-[#E9EDF4] bg-transparent px-3 py-[10px] text-body-color outline-none focus:border-primary focus-visible:shadow-none dark:border-dark-3 dark:text-dark',
};

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 343,
    },
  },
};

const menuItems = [
  {
    title: 'English',
    value: 'en',
    flag: UKFlag,
  },
  {
    title: 'Spanish',
    value: 'es',
    flag: ESFlag,
  },
  {
    title: 'French',
    value: 'fr',
    flag: FRFlag,
  },
  {
    title: 'Norwegian',
    value: 'no',
    flag: ESFlag,
  },
];

const Login = () => {
  const context = useContext(LanguageContext);
  const [inputs, setInputs] = useState({ username: '', password: '' });
  const [passwordVisibilty, togglePassword] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [cookies, setCookies] = useCookies();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const dispatch = useDispatch();
  const intl = useIntl();
  const fcmToken = sessionStorage.getItem('fcm');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let useClickOutside = handler => {
    let domNode = useRef();
    useEffect(() => {
      let maybeHandler = event => {
        if (!domNode.current.contains(event.target)) {
          handler();
        }
      };
      document.addEventListener('mousedown', maybeHandler);
      return () => {
        document.removeEventListener('mousedown', maybeHandler);
      };
    });

    return domNode;
  };

  let domNode = useClickOutside(() => {
    setDropdownOpen(false);
  });

  const handleInputChange = (e: any) => {
    setError(false);
    setErrorMessage('Error');
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    reset();
  };
  const registerFCMToken = (payload: any) => {
    return registerFCMTokenApi.create(
      { v1: 'v_d2', token: cookies.app_token },
      JSON.stringify(payload),
    );
  };
  const loginUser = (payload: any) => {
    return signin.create({}, JSON.stringify(payload));
  };
  const handleLogin = e => {
    e.preventDefault();
    if (inputs.username.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyUsername' }));
    } else if (inputs.password.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyPassword' }));
    } else {
      mutate(inputs);
    }
  };

  const handleClickShowPassword = () => {
    togglePassword(!passwordVisibilty);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const lovesNamingConvention = (stores: any = selectAllStores) => {
    if (stores.length > 50) {
      return stores;
    }
    stores?.filter((store: any) => {
      if (store?.store_type === 'LovesStoreType') {
        store = editLovesStoreName(store);
      }
    });
    return stores;
  };

  const editLovesStoreName = (store: any) => {
    if (store.address_json?.length) {
      let address = JSON.parse(store.address_json);
      let clonedObject = { ...address };
      clonedObject = {
        ...clonedObject,
        retailer: `${store.retailer_store_id} - ${address.city}, ${address.state}`,
      };
      // store.retailer = `${store.retailer_store_id} - ${address.city}, ${address.state}`;
      return clonedObject;
    }
    return store;
  };
  const {
    isLoading: FCMLoading,
    mutate: FCMMutate,
    reset: FCMReset,
  } = useMutation(registerFCMToken, {
    onError: (serverError: any) => {
      datadogLogs.logger.error('registerFCMToken ErrorHandler', serverError);
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: async successData => {
      console.log('Success Data', successData);
      // setSuccess(true);
    },
  });
  const { isLoading, mutate, reset } = useMutation(loginUser, {
    onError: (error: any) => {
      setError(true);
      if (error.indexOf(' password') != -1) {
        setErrorMessage(
          `${intl.formatMessage({ id: 'invalidPassword' })} ${error
            .split(' ')
            .slice(4, error.length)
            .join(' ')}`,
        );
      } else if (error.indexOf('username') != -1) {
        setErrorMessage(
          `${intl.formatMessage({ id: 'invalidUsername' })} ${error
            .split(' ')
            .slice(2, error.length)
            .join(' ')}`,
        );
      } else {
        setErrorMessage(error);
      }
      setStoreLoading(false);
    },

    onSuccess: async response => {
      if (get(response, 'data.success', false)) {
        let currentURL = window.location.href.split('//')[1].split('/')[0];
        if (currentURL.includes(':')) {
          currentURL = currentURL.split(':')[0];
        }
        const {
          access_token,
          user_id = '',
          username = '',
          email,
          can_refund = false,
          can_edit = false,
          can_add = false,
          can_delete = false,
          allow_analytics = false,
          allow_settlement_report = false,
          retailer = '',
          stores = [],
          store_id,
          ...rest
        } = get(response, 'data.result', {});

        const userPayload = {
          userData: {
            user_id,
            username,
            email,
          },
          permissions: {
            can_refund,
            can_edit,
            can_add,
            can_delete,
            allow_analytics,
            allow_settlements: allow_settlement_report,
          },
        };
        let includeDemoAndInactiveStores =
          process.env.REACT_APP_ENV_TYPE !== 'Production';

        setStoreLoading(true);
        const storesResponse = await storesApi.query({
          v1: true,
          token: access_token,
          show_demo: true,
        });

        let storesData = get(storesResponse, 'data.data', []);
        storesData = lovesNamingConvention(storesData);

        // let modifyStoresData = computeStores(storesData);
        let modifyStoresData = storesData.filter((item: any) => {
          return !item.retailer.split(' ').includes('All');
        });

        let defaultStore = modifyStoresData.find(
          (store: any) => store.store_id === store_id,
        );

        let active_store = defaultStore?.demo
          ? modifyStoresData.find((store: any) => !store?.demo)
          : modifyStoresData.find((store: any) => store.store_id === store_id);

        let regions = uniqBy(
          modifyStoresData
            .map((item: any) => {
              if (item.region) {
                return {
                  title: item.region,
                  value: item.region,
                };
              }
            })
            .filter((item: any) => item && item.value),
          'value',
        );
        let retailers = uniqBy(
          modifyStoresData
            .map((item: any) => {
              if (item.store_type) {
                return {
                  title: startCase(item.store_type.replace('StoreType', '')),
                  value: item.store_type,
                };
              }
            })
            .filter((item: any) => item && item.value),
          'value',
        );

        let activeStorePayload = active_store
          ? active_store
          : modifyStoresData[0];

        let defaultRegion = [
          {
            value: activeStorePayload?.region,
            title: activeStorePayload?.region,
            type: 'single',
          },
        ];
        let defaultRetailers = [
          {
            title: startCase(
              activeStorePayload?.store_type.replace('StoreType', ''),
            ),
            value: activeStorePayload?.store_type,
            type: 'single',
          },
        ];
        dispatch({
          type: 'SET_FCM_TOKEN',
          payload: fcmToken,
        });
        dispatch({
          type: 'UPDATE_USER_DATA',
          payload: userPayload,
        });
        dispatch({
          type: 'SET_CURRENT_STORE_DATA',
          payload: activeStorePayload,
        });
        dispatch({
          type: 'SET_STORES',
          payload: modifyStoresData,
        });
        dispatch({
          type: 'SET_REGIONS',
          payload: regions,
        });
        dispatch({
          type: 'SET_RETAILERS',
          payload: retailers,
        });
        dispatch({
          type: 'SET_ACTIVE_STORES',
          payload: getRequiredStores(
            modifyStoresData,
            includeDemoAndInactiveStores,
            includeDemoAndInactiveStores,
          ),
        });
        dispatch({ type: 'SET_SELECTED_REGIONS', payload: [] });
        dispatch({ type: 'SET_SELECTED_RETAILERS', payload: [] });
        dispatch({
          type: 'INCLUDE_DEMO_STORES',
          payload: includeDemoAndInactiveStores,
        });
        dispatch({
          type: 'INCLUDE_INACTIVE_STORES',
          payload: includeDemoAndInactiveStores,
        });
        dispatch({
          type: 'LOGIN_USER',
        });
        dispatch({
          type: 'CHANGE_SETTLEMENTS_SELECTED_RETAILER',
          payload: {
            title: startCase(
              modifyStoresData[0].store_type.replace('StoreType', ''),
            ),
            value: modifyStoresData[0].store_type,
          },
        });
        console.log({ modifyStoresData });

        dispatch({
          type: 'CHANGE_PAGE_NUMBER',
          payload: {
            ccOrders: 1,
            orders: 1,
            kiosk: 1,
          },
        });
        await setCookies('app_token', access_token, {
          path: '/',
          domain: currentURL,
        });
        if (fcmToken) {
          FCMMutate({
            device_id: parseInt(uuidv4(), 16),
            registration_id: fcmToken,
            source: 'FCM',
          });
        }
        setStoreLoading(false);
      } else {
        setError(true);
        setErrorMessage('Error in login');
        setStoreLoading(false);
      }
    },
  });
  const DropdownItem = ({ label, value, img }) => {
    return (
      <div
        className="inline-flex py-2 pl-5 text-left text-base text-[#637381] no-underline hover:bg-slate-200 w-[100%!important]"
        onClick={() => {
          context.changeLanguage(value);
          setDropdownOpen(!dropdownOpen);
        }}
      >
        {label}
        <span className="ml-auto my-auto mr-[10px]">
          <img src={img} alt={label} />
        </span>
      </div>
    );
  };
  return (
    <section
      className="bg-tg-bg py-20 dark:bg-whitesmoke lg:py-[120px]"
      ref={domNode}
    >
      <div className="container mx-auto">
        <div className="bg-white dark:bg-dark-2">
          <div className="flex flex-wrap items-stretch">
            <div className="w-full lg:w-1/2">
              <div className="w-full px-6 py-14 sm:p-[70px] sm:px-12 xl:px-[90px]">
                <h2 className="mb-10 text-[32px] font-bold text-dark dark:text-white text-left">
                  Sign In
                </h2>
                <form onSubmit={handleLogin} target="_self">
                  <div className="mb-8">
                    <label className="mb-2.5 block text-[16px] text-dark dark:text-white text-left">
                      {intl.formatMessage({ id: 'username' })}
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        name="username"
                        onChange={handleInputChange}
                        className={
                          error ? classes.inputError : classes.inputNormal
                        }
                      />
                      {error && (
                        <span className="absolute top-1/2 right-4 -translate-y-1/2">
                          <svg
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                              fill="#DC3545"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mb-8">
                    <label className="mb-2.5 block text-[16px] text-dark dark:text-white text-left">
                      {intl.formatMessage({ id: 'password' })}
                    </label>
                    <div className="relative">
                      <input
                        type="password"
                        name="password"
                        onChange={handleInputChange}
                        onKeyPress={(e: any) => {
                          if (e.key === 'Enter') {
                            handleLogin();
                          }
                        }}
                        className={
                          error ? classes.inputError : classes.inputNormal
                        }
                      />
                      {error && (
                        <span className="absolute top-1/2 right-4 -translate-y-1/2">
                          <svg
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                              fill="#DC3545"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                              fill="#DC3545"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                    <p
                      className="mt-[5px] text-sm text-red text-left"
                      style={{ visibility: error ? 'visible' : 'hidden' }}
                    >
                      {errorMessage}
                    </p>
                  </div>
                  <button
                    onClick={handleLogin}
                    disabled={isLoading || storeLoading}
                    className="mb-8 w-full inline-flex h-12 items-center justify-center gap-2.5 cursor-pointer rounded-md bg-primary px-[14px] py-3 text-base font-medium text-white transition hover:bg-opacity-90"
                  >
                    <span>
                      {(isLoading || storeLoading) && (
                        <svg
                          className="animate-spin"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            opacity="0.5"
                            cx="10"
                            cy="10"
                            r="9"
                            stroke="white"
                            stroke-width="2"
                          />
                          <mask id="path-2-inside-1_2527_20936" fill="white">
                            <path d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z" />
                          </mask>
                          <path
                            d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z"
                            stroke="white"
                            stroke-width="4"
                            mask="url(#path-2-inside-1_2527_20936)"
                          />
                        </svg>
                      )}
                    </span>
                    {isLoading || storeLoading
                      ? 'Loading...'
                      : intl.formatMessage({ id: 'login' })}
                  </button>
                </form>
                <div className="flex flex-wrap justify-between">
                  <a
                    href="/forgot-password"
                    className="mb-2 mr-2 inline-block text-base text-dark hover:text-primary hover:underline dark:text-dark-6"
                  >
                    {intl.formatMessage({ id: 'forgotPassword' })}
                  </a>
                  <p className="mb-2 text-base text-dark dark:text-dark-6">
                    <span className="pr-0.5"> Not a member yet? </span>

                    <a href="/signup" className="text-primary hover:underline">
                      {intl.formatMessage({ id: 'signUp' })}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="relative h-full w-full overflow-hidden bg-primary">
                <div
                  className="relative inline-block text-left z-50"
                  style={{ top: '5%', left: '25%' }}
                >
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className={`flex items-center rounded-[6px] pl-[15px] py-[8px] bg-white text-base font-medium text-[#637381] w-[150px]`}
                  >
                    {languages[context.locale]}
                    <span className="ml-auto my-auto mr-[10px]">
                      <img src={flags[context.locale]} alt={context.locale} />
                    </span>
                  </button>
                  <div
                    className={`shadow-1 dark:shadow-box-dark absolute left-0 z-40 mt-2 w-full rounded-md bg-white py-[10px] transition-all ${
                      dropdownOpen
                        ? 'top-full opacity-100 visible'
                        : 'top-[110%] invisible opacity-0'
                    }`}
                  >
                    {menuItems.map((item: any) => (
                      <DropdownItem
                        label={item.title}
                        value={item.value}
                        img={item.flag}
                      />
                    ))}
                  </div>
                </div>
                <div className="flex h-full items-end p-8 sm:p-14">
                  <h3 className="text-[28px]/[34px] font-bold text-white text-left mb-20">
                    Hey <br />
                    Welcome <br />
                    Back
                  </h3>
                  <div>
                    <span className="absolute left-0 top-0">
                      <svg
                        width="415"
                        height="355"
                        viewBox="0 0 415 355"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M415 107.5C415 244.19 304.19 355 167.5 355C30.8095 355 -80 244.19 -80 107.5C-80 -29.1905 30.8095 -140 167.5 -140C304.19 -140 415 -29.1905 415 107.5Z"
                          fill="url(#paint0_linear_1179_8)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1179_8"
                            x1="167.5"
                            y1="-140"
                            x2="167.5"
                            y2="355"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0.177083"
                              stopColor="white"
                              stopOpacity="0.16"
                            />
                            <stop
                              offset="1"
                              stopColor="white"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute left-0 top-0">
                      <svg
                        width="177"
                        height="354"
                        viewBox="0 0 177 354"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M177 177C177 274.754 97.7544 354 0 354C-97.7544 354 -177 274.754 -177 177C-177 79.2456 -97.7544 0 0 0C97.7544 0 177 79.2456 177 177Z"
                          fill="url(#paint0_linear_1179_7)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1179_7"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="354"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0.177083"
                              stopColor="white"
                              stopOpacity="0.2"
                            />
                            <stop
                              offset="1"
                              stopColor="white"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute bottom-16 right-20">
                      <svg
                        width="85"
                        height="85"
                        viewBox="0 0 85 85"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M42.5 -1.85773e-06C65.9721 -2.88373e-06 85 19.0279 85 42.5C85 65.9721 65.9721 85 42.5 85C19.0279 85 -8.31736e-07 65.9721 -1.85773e-06 42.5C-2.88373e-06 19.0279 19.0279 -8.31736e-07 42.5 -1.85773e-06Z"
                          fill="url(#paint0_linear_1179_6)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1179_6"
                            x1="-1.85774e-06"
                            y1="42.5"
                            x2="85"
                            y2="42.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              offset="0.177083"
                              stopColor="white"
                              stopOpacity="0.16"
                            />
                            <stop
                              offset="1"
                              stopColor="white"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
