import React, { useState, useEffect } from 'react';

import {
  Toolbar,
  Typography,
  Paper,
  Grid,
  Box,
  Link,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  currencyFormat,
  getCurrency,
  getDate,
  getDateDifference,
  updateDecimals,
} from '../../utils';
import { useCookies } from 'react-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import {
  CHANGE_START_DATE,
  CHANGE_END_DATE,
} from '../../redux/actions/action_types';
import { useQuery } from 'react-query';
import { settlementSummaryApi } from 'src/services/settlements';
import CheckIcon from 'src/assets/svgIcons/CheckIcon';
import QuestionIcon from 'src/assets/svgIcons/QuestionIcon';
import { Header } from 'src/components';
import DatePicker from '../analytics/components/datePicker';
import { FormattedMessage } from 'react-intl';
import { startCase, uniqBy } from 'lodash';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    backgroundColor: '#8f9bb3c2',
  },
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#8f9bb3c2',
    padding: 16,
  },
  paper: {
    // minHeight: 280,
    width: 350,
    border: 10,
    height: '100%',
  },
  rightItemsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 8,
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getCardStyle = (matched: any) => {
  let section = {
    marginTop: 16,
    padding: 8,
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: 'space-between',
  };
  if (matched === true) {
    return {
      ...section,
      borderColor: '#3758F9',
    };
  } else if (matched === false) {
    return {
      ...section,
      borderColor: '#ffaa00',
    };
  } else {
    return {
      ...section,
      borderColor: '#d5d5d5',
    };
  }
};

const Settlements = (props: any) => {
  let { history } = props;
  let yesterday = new Date();
  const dispatch = useDispatch();
  yesterday.setDate(yesterday.getDate() - 1);
  const [filters, setFilters] = useState({});
  const { active_stores } = useSelector((state: any) => state.store);
  const retailers = uniqBy(
    active_stores
      .map((item: any) => {
        if (item.store_type) {
          return {
            title: startCase(item.store_type.replace('StoreType', '')),
            value: item.store_type,
          };
        }
      })
      .filter((item: any) => item && item.value),
    'value',
  );

  const [toastVisible, showToast] = React.useState(false);

  let {
    settlements: { startDate, endDate, selectedRetailer = retailers[0] },
  } = useSelector((state: any) => state);

  const [cookies] = useCookies();
  const classes = useStyles();

  const navigateToDetailView = (type: any, psp: any = '', regionData: any) => {
    history.push({
      pathname: `/reportDetails/poslog`,
      state: { type, filters, regionData, startDate, endDate, psp },
    });
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason: any,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    showToast(false);
  };

  const appendCurrency = (currency: any, value: any) => {
    let number = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currencyFormat(currency),
    }).format(value);
    return number;
    // return isNaN(Number(number))
    //   ? '--'
    //   : `${getCurrency(currency)} ${number
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  const fetchSettlementSummary = async () => {
    try {
      let storeType = selectedRetailer.value;
      let params = { v1: true, token: cookies.app_token };
      let data = {
        store_type: storeType,
        start_date: getDate(startDate),
        end_date: getDate(endDate),
      };
      return settlementSummaryApi.create(params, data).then((res: any) => {
        console.log({ res });
        let { csv_download_delimiter_formats = [], reports_region_wise = [] } =
          res.data;
        reports_region_wise.forEach((report: any) => {
          const poslogs = report.reports_sub.filter((report_sub: any) => {
            return report_sub.type === 'poslog_recon';
          });
          const paypouts = report.reports_sub.filter((report_sub: any) => {
            return report_sub.type === 'payout_recon';
          });
          report.reports_sub = [
            { header: 'Transactions', logs: poslogs, backgroundColor: '#fff' },
            { header: 'Payout', logs: paypouts, backgroundColor: '#EDF1F7' },
          ];
        });
        let filters = reports_region_wise.map((item: any) => {
          return {
            region: item?.region,
            region_name: item.region_name,
            pspList: item?.payout_psp_payment_methods,
          };
        });
        dispatch({
          type: 'CHANGE_DELIMITER',
          payload: csv_download_delimiter_formats,
        });
        setFilters(filters);
        return res?.data;
      });
    } catch (e) {
      console.log({ e });
    }
  };

  const selectRetailerValue = (event: any) =>
    dispatch({
      type: 'CHANGE_SETTLEMENTS_SELECTED_RETAILER',
      payload: event.target.value,
    });

  const { isLoading, data, isFetching, error }: any = useQuery(
    [
      'analyticsData',
      'store_id',
      startDate,
      endDate,
      active_stores,
      selectedRetailer,
    ],
    fetchSettlementSummary,
    { refetchOnWindowFocus: false },
  );

  const renderRetailer = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <InputLabel
          id="demo-simple-select-label"
          style={{
            marginLeft: 8,
            marginBottom: 8,
            fontWeight: 'bold',
            color: '#8992a3',
          }}
        >
          <FormattedMessage id="retailer" defaultMessage="Retailer" />
        </InputLabel>
        <Select
          id="retailer"
          value={retailers.find(
            (item: any) => item.value === selectedRetailer.value,
          )}
          variant={'standard'}
          style={{
            minWidth: 150,
            marginRight: 8,
            marginLeft: 8,
          }}
          onChange={selectRetailerValue}
        >
          {retailers.map((item: any) => (
            <MenuItem key={item.value} value={item}>
              <Typography variant="subtitle1">{item.title}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const rightAccesory = () => (
    <Box className={classes.rightItemsContainer}>
      {renderRetailer()}
      <DatePicker
        label="DATE RANGE"
        date={startDate}
        handleDateChange={(date: any) =>
          dispatch({ type: CHANGE_START_DATE, payload: date })
        }
        style={{ maxWidth: 160, width: 160 }}
      />
      <DatePicker
        label=" "
        date={endDate}
        handleDateChange={(date: any) =>
          dispatch({ type: CHANGE_END_DATE, payload: date })
        }
        style={{ marginLeft: 8, maxWidth: 160, width: 160 }}
      />
    </Box>
  );

  return (
    <>
      <Box
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <Header
          title={
            <FormattedMessage
              id="settlementSummary"
              defaultMessage="Settlement Summary"
            />
          }
          subtitle={
            <FormattedMessage
              id="settlementReports"
              defaultMessage="Settlement reports"
            />
          }
          showStores={false}
          rightAccessory={rightAccesory}
        />
        <Hidden only={['lg', 'xl']}>{rightAccesory()}</Hidden>
        {isLoading ? (
          <>
            <Box
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            {data?.reports_region_wise?.length > 0 ? (
              <Grid
                container
                className={classes.container}
                spacing={2}
                // alignItems="center"
              >
                {data?.reports_region_wise?.map((item: any, index: number) => {
                  let {
                    region,
                    region_name,
                    currency,
                    reports_sub = [],
                  } = item;
                  let region_flag_url =
                    'https://static-assets.mishipay.com/country_flags/svg/' +
                    region.toLocaleLowerCase() +
                    '.svg';

                  return (
                    <Grid key={index} item>
                      <Box
                        borderTop={4}
                        borderColor="#4180e9"
                        style={{ maxHeight: '100%' }}
                      >
                        <Paper className={classes.paper} variant="outlined">
                          <Box
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 16,
                              paddingTop: 16,
                              paddingRight: 16,
                              paddingLeft: 16,
                            }}
                          >
                            <img
                              src={region_flag_url}
                              style={{ height: 24, width: 24 }}
                            />
                            <Typography
                              variant="h6"
                              style={{ marginLeft: 8, fontWeight: 'bold' }}
                            >
                              {region_name}
                            </Typography>
                          </Box>
                          <Divider />
                          {reports_sub?.length ? (
                            reports_sub.map(
                              (report_sub: any, index: number) => {
                                return report_sub?.logs?.length ? (
                                  <div
                                    key={index}
                                    style={{
                                      background: report_sub.backgroundColor,
                                      padding: 16,
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                        flexDirection: 'row',
                                        display: 'flex',
                                        alignItems: 'left',
                                      }}
                                    >
                                      {report_sub.header}
                                    </Typography>
                                    {report_sub?.logs?.map(
                                      (sub_report_item: any, index: number) => {
                                        let {
                                          type,
                                          psp,
                                          psp_display_name,
                                          link_title,
                                          matched,
                                          data,
                                        } = sub_report_item;
                                        let sectionStyle =
                                          getCardStyle(matched);
                                        let icon = () =>
                                          matched === true ? (
                                            <CheckIcon htmlColor={'#00e096'} />
                                          ) : matched === false ? (
                                            <QuestionIcon
                                              htmlColor={'#ffaa00'}
                                            />
                                          ) : (
                                            <></>
                                          );
                                        return (
                                          <Paper
                                            key={index}
                                            variant="outlined"
                                            style={{
                                              ...sectionStyle,
                                              background:
                                                report_sub.backgroundColor,
                                            }}
                                          >
                                            {data.map(
                                              (items: any, index: number) => (
                                                <div
                                                  key={index}
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                    marginTop: 4,
                                                  }}
                                                >
                                                  <Typography
                                                    variant="subtitle2"
                                                    style={{
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    {items.title}
                                                  </Typography>
                                                  <span
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <Typography variant="body2">
                                                      {appendCurrency(
                                                        currency,
                                                        items?.value,
                                                      )}
                                                    </Typography>
                                                  </span>
                                                </div>
                                              ),
                                            )}
                                            <div
                                              key={index}
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginTop: 4,
                                              }}
                                            >
                                              <u>
                                                <Link
                                                  component="button"
                                                  onClick={() => {
                                                    navigateToDetailView(
                                                      type,
                                                      psp,
                                                      {
                                                        region,
                                                        region_name,
                                                      },
                                                    );
                                                  }}
                                                  style={{
                                                    cursor: 'pointer',
                                                    color: '#3366ff',
                                                  }}
                                                >
                                                  <Typography variant="subtitle2">
                                                    {link_title}
                                                  </Typography>
                                                </Link>
                                              </u>
                                              {icon()}
                                            </div>
                                          </Paper>
                                        );
                                      },
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                );
                              },
                            )
                          ) : (
                            <>
                              <Box style={{ padding: 16 }}>
                                <Typography variant="subtitle2">
                                  No Data Available
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Paper>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  backgroundColor: '#8f9bb3c2',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  <FormattedMessage
                    id="noReports"
                    defaultMessage="No Reports Available"
                  />
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={toastVisible}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning">
          <FormattedMessage
            id="dateLimit"
            defaultMessage="Please select a date less than 60 days"
          />
        </Alert>
      </Snackbar>
    </>
  );
};

export default React.memo(Settlements);
