import { combineReducers } from 'redux';
import user from './user';
import theme from './theme';
import store from './store';
import screens from './screens';
import settlements from './settlements';
import analytics from './analytics';
import orders from './orders';

export default combineReducers({
  user,
  theme,
  store,
  screens,
  settlements,
  analytics,
  orders,
});
