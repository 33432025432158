import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3758F9',
    },
    color_dark_text: {
      main: '#111928',
    },
    color_white: {
      main: '#ffffff',
    },
    color_black: {
      main: '#000000',
    },
    color_light_grey: {
      main: '#637381',
    },

    color_text_grey: {
      main: '#8f9bb3',
    },
    color_icon_button: {
      main: '#4180e9',
    },
    success: {
      main: '#008562',
      background: '#E8FBF6',
    },
    warning: {
      main: '#D97706',
      background: '#FFFBEB',
    },
    info: {
      main: '#3758F9',
    },
    error: {
      main: '#E10E0E',
      background: '#FEF3F3',
    },
    promo_background: {
      main: '#ffea26',
    },
    color_dark_green: {
      main: '#0A7013',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
});

lightTheme.overrides = {
  MuiInput: {
    root: {
      padding: 8,
      borderRadius: 4,
      backgroundColor: '#e4e9f2',
      color: '#000000',
    },
  },
};

lightTheme.props = {
  MuiInput: {
    disableUnderline: true,
  },
};

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#3758F9',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
});

export { lightTheme, darkTheme };
