import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Link,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import IntroImage from '../../assets/introImage.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'scroll',
    },
    media: {
      height: 350,
      //   paddingTop: '56.25%', // 16:9
      width: 330,
      alignSelf: 'center',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }),
);

const IntroModal = ({ onClose }: any) => {
  const classes = useStyles();
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  return (
    <Card className={classes.root}>
      <CardHeader title="Mishipay Dashboard 3.0" />
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <img
          src={IntroImage}
          alt="Mishipay Dashboard"
          className={classes.media}
        />
      </Box>

      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          In this latest version of the Dashboard, you will find a new design,
          as well as the ability to select multiple stores to monitor analytics
          and performance even further.
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary" component="p">
          Other design changes are also made to improve efficiency when using
          the Dashboard. Take a look and explore. For any issues, simply get in
          touch with MishiPay via{' '}
          <Link href="#" onClick={preventDefault}>
            support@mishipay.com
          </Link>
        </Typography>
      </CardContent>
      <CardActions
        style={{
          alignContent: 'center',
          justifyContent: 'center',
          marginBottom: 16,
        }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          size="large"
          color="primary"
        >
          OK
        </Button>
      </CardActions>
    </Card>
  );
};

export default IntroModal;
