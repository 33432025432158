import { Input, Button, Box } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { BrowserCodeReader, BrowserMultiFormatReader } from '@zxing/browser';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import ScannerFrame from '../../../../assets/scanner-frame.svg';

const SerialNumber = (props: any) => {
  const intl = useIntl();
  let { old_sn, item_id, handler } = props;
  const [inputData, setInputData] = useState({
    isValid: false,
    value: '',
    isScanned: false,
  });
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);
  const codeReader = new BrowserMultiFormatReader(undefined, {
    delayBetweenScanSuccess: 2000,
  });
  let scannerControls: any = useRef(null);

  useEffect(() => {
    if (old_sn) {
      setInputData({
        isValid: true,
        value: old_sn,
        isScanned: false,
      });
    }
    BrowserCodeReader.listVideoInputDevices().then(
      async (videoInputDevices: string | any[]) => {
        if (videoInputDevices.length) {
          codeReader
            .decodeFromVideoDevice(
              videoInputDevices[0].deviceId,
              'video',
              (result: any, err: any) => {
                if (result) {
                  setInputData({
                    value: result.getText(),
                    isValid: true,
                    isScanned: true,
                  });
                }
              },
            )
            .then(scanner => {
              scannerControls.current = scanner;
            })
            .catch(() => {
              setIsPermissionDenied(true);
            });
        }
      },
    );
  }, [old_sn]);

  const isScannerStopped = () => {
    if (scannerControls.current) {
      scannerControls.current.stop();
      return true;
    }
    return isPermissionDenied;
  };

  return (
    <Dialog open={true} onClose={() => {}}>
      <DialogTitle
        style={{
          fontWeight: '700',
          fontSize: '18px',
        }}
        disableTypography={true}
      >
        {intl.formatMessage({ id: 'serialNumberTitle' })}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            position: 'relative',
            backgroundColor: '#4F4F4F',
            height: '145px',
            width: '315px',
            marginBottom: '10px',
          }}
        >
          <video
            id={'video'}
            hidden={inputData.isScanned}
            style={{
              height: '145px',
              width: '315px',
              objectFit: 'cover',
            }}
          />

          <span
            style={{
              display: 'flex',
              position: 'absolute',
              color: '#ffffff',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              fontWeight: 700,
              fontSize: '18px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              if (inputData.isScanned) {
                setInputData({
                  value: old_sn,
                  isValid: !!old_sn,
                  isScanned: false,
                });
              }
            }}
          >
            {!inputData.isScanned && (
              <img src={ScannerFrame} style={{ position: 'absolute' }} />
            )}
            {inputData.isScanned ? 'Scan again?' : 'Scan serial number'}
          </span>
        </div>
        <Input
          value={inputData.value}
          autoComplete="off"
          fullWidth={true}
          placeholder="Or enter serial number manually"
          onChange={event => {
            event.target.value = event.target.value.replace(
              /[^a-zA-Z0-9]/g,
              '',
            );
            setInputData(prevState => ({
              value: event.target.value,
              isValid: !!event.target.value,
              isScanned: prevState.isScanned,
            }));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Box
          component={'div'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '8px 15px',
          }}
        >
          <Button
            fullWidth={true}
            color="primary"
            style={{
              fontWeight: '700',
              fontSize: '14px',
              height: '40px',
            }}
            onClick={() => {
              if (isScannerStopped()) {
                handler({
                  new_sn: inputData.value,
                  old_sn,
                  item_id,
                });
              }
            }}
            variant={'contained'}
            disabled={!inputData.isValid}
          >
            Ok
          </Button>
          <Button
            fullWidth={true}
            style={{
              marginTop: '20px',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '16px',
              color: '#666666',
              height: '40px',
            }}
            color="default"
            onClick={() => {
              if (isScannerStopped()) {
                handler();
              }
            }}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default SerialNumber;
