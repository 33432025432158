const DataStats = ({ data }: any) => {
  let { body } = data;

  return (
    <div
      className="w-full space-y-3 rounded-lg bg-white p-[30px]"
      style={{ boxShadow: '0px 1px 3px 0px rgba(166, 175, 195, 0.40)' }}
    >
      <>
        {body &&
          body.map((item: any, index: number) => {
            return (
              <>
                <DataStatsCard
                  name={item.title}
                  number={item.value}
                  icon={item.icon}
                  name2={item.title2}
                  number2={item.value2}
                ></DataStatsCard>
              </>
            );
          })}
      </>
    </div>
  );
};
const DataStatsCard = ({ name, number, icon, name2, number2 }) => {
  return (
    <div className="flex items-center border-dashed border-stroke border-[#DFE4EA] text-left [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-2">
      <div className="flex w-full items-center">
        <div className="mr-3 flex h-[44px] w-full max-w-[44px] items-center justify-center rounded-[5px] bg-[#F5F7FF] text-primary xs:mr-5 xs:h-[54px] xs:max-w-[54px]">
          <img src={icon} />
        </div>
        <div>
          <h5 className="text-lg font-bold text-dark dark:text-white xs:text-2xl">
            {number}
          </h5>
          <p className="text-sm text-body-color dark:text-dark-6 xs:text-base mb-1">
            {name}
          </p>
        </div>
        {number2 && (
          <div className="ml-[40px]">
            <h5 className="text-lg font-bold text-dark dark:text-white xs:text-2xl">
              {number2}
            </h5>
            <p className="text-sm text-body-color dark:text-dark-6 xs:text-base mb-1">
              {name2}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default DataStats;
