import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ChatCenteredTextIcon = (props: any) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      style={{
        fill: 'none',
      }}
    >
      <path
        d="M9.50006 9.75H15.5001"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50006 12.75H15.5001"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5315 18.3641L13.1431 20.6781C13.0764 20.7891 12.9822 20.8811 12.8695 20.9449C12.7568 21.0087 12.6295 21.0422 12.4999 21.0422C12.3704 21.0422 12.2431 21.0087 12.1304 20.9449C12.0177 20.8811 11.9235 20.7891 11.8568 20.6781L10.4684 18.3641C10.4018 18.253 10.3075 18.1611 10.1948 18.0973C10.0821 18.0335 9.95481 18 9.8253 18H4.24994C4.05103 18 3.86026 17.9209 3.71961 17.7803C3.57896 17.6396 3.49994 17.4489 3.49994 17.25V5.25C3.49994 5.05109 3.57896 4.86032 3.71961 4.71967C3.86026 4.57902 4.05103 4.5 4.24994 4.5H20.7499C20.9489 4.5 21.1396 4.57902 21.2803 4.71967C21.4209 4.86032 21.4999 5.05109 21.4999 5.25V17.25C21.4999 17.4489 21.4209 17.6397 21.2803 17.7803C21.1396 17.921 20.9489 18 20.7499 18L15.1746 18C15.0451 18 14.9178 18.0335 14.8051 18.0973C14.6924 18.1611 14.5981 18.253 14.5315 18.3641V18.3641Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default ChatCenteredTextIcon;
