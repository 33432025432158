import React, { useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { ErrorBoundary as Wrapper } from 'react-error-boundary';
import { LogoutContext } from 'src/components/LogoutWrapper';
import { datadogLogs } from '@datadog/browser-logs';

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  const context = useContext(LogoutContext);

  const refreshApp = () => {
    try {
      console.log({ error });
      context.logoutUser();
      resetErrorBoundary();
    } catch (e: any) {}
  };

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography>Oops! Something went wrong</Typography>
      <Button onClick={refreshApp}>Click here to logout and try again.</Button>
    </Box>
  );
};

const myErrorHandler = (error: Error) => {
  datadogLogs.logger.error('ErrorHandler', error);
};

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <Wrapper FallbackComponent={ErrorFallback} onError={myErrorHandler}>
    {children}
  </Wrapper>
);

export default ErrorBoundary;
