import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Header,
  Chart,
  TextChart,
  BarChart,
  DatePicker,
  DateRangePicker,
} from './components';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Link,
  TablePagination,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  itemsScannedApi,
  loginSignupAnalyticsApi,
  ratingsApi,
  retailerBreakdownApi,
  transactionBreakdownApi,
} from 'src/services/analytics';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Masonry from 'react-masonry-css';
import {
  currencyFormat,
  eventBus,
  getCurrency,
  getSelectedStoreAppClipIds,
} from 'src/utils';
import { startCase } from 'lodash';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import DataStats from './components/dataStats';
import ShoppingCart11 from '../../assets/shopping-cart-11.svg';
import ShoppingCart1 from '../../assets/shopping-cart-1.svg';
import TransactionValue from '../../assets/transaction-value.svg';
import AvgBasketValue from '../../assets/avg-basket-value.svg';
import TotalItemsPurchased from '../../assets/box-4.svg';
import ItemsScanned from '../../assets/items-scanned.svg';
import SignupSessions from '../../assets/signup-sessions.svg';
import LoginSessions from '../../assets/login-sessions.svg';
import { RatingsChart } from './components/ratings';
import { DataTable, DropDown } from 'src/components';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  dateRange: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  bodyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    height: '100%',
  },
  body: {
    flex: 0.897,
    overflow: 'hidden',
  },
  gridContainer: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOrdersContainer: {
    display: 'flex',
    width: '100%',
    padding: 30,
    flex: 0.897,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOrders: {
    width: 343,
    height: 367,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F9FC',
    flexDirection: 'column',
  },

  iconContainer: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#EDF1F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 80,
    height: 80,
  },

  noOrdersText: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#8f9bb3',
  },
  table: {
    minWidth: 650,
  },
}));

const Analytics = (props: any) => {
  const classes = useStyles();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const [dateTitle, setdateTitle] = useState('Quick Picks');
  const intl = useIntl();
  const {
    selected_regions,
    regions,
    selected_retailers,
    active_stores,
    retailers,
    stores,
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);
  const {
    analytics: { startDate, endDate },
  } = useSelector((state: any) => state);
  const currencies = [
    { value: 'gbp', title: 'GBP' },
    { value: 'usd', title: 'USD' },
    { value: 'cad', title: 'CAD' },
    { value: 'inr', title: 'INR' },
    { value: 'dkk', title: 'DKK' },
    { value: 'sek', title: 'SEK' },
    { value: 'nok', title: 'NOK' },
    { value: 'chf', title: 'CHF' },
    { value: 'eur', title: 'EUR' },
    { value: 'mvr', title: 'MVR' },
    { value: 'aed', title: 'AED' },
    { value: 'aud', title: 'AUD' },
    { value: 'sar', title: 'SAR' },
    { value: 'aud', title: 'AUD' },
    { value: 'cad', title: 'CAD' },
  ];
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  let month = new Date();
  month.setMonth(month.getMonth() - 1);
  month.setHours(0, 0, 0, 0);
  const dateRng = [
    { value: new Date(), title: 'Today' },
    { value: yesterday, title: 'Yesterday' },
    { value: month, title: 'Last 30 Days' },
  ];
  const regionCurrencies = {
    CH: 'CHF',
    CZ: 'CZK',
    DK: 'DKK',
    FI: 'EUR',
    IT: 'EUR',
    NH: 'EUR',
    IR: 'EUR',
    GR: 'EUR',
    FR: 'EUR',
    NL: 'EUR',
    ES: 'EUR',
    EU: 'EUR',
    DE: 'EUR',
    UK: 'GBP',
    GB: 'GBP',
    IL: 'ILS',
    NO: 'NOK',
    SE: 'SEK',
    US: 'USD',
    Sar: 'SAR',
    SA: 'AED',
    AE: 'AED',
    USA: 'USD',
    CA: 'CAD',
    TR: 'TRY',
    IN: 'INR',
    AU: 'AUD',
    RU: 'RUB',
    MV: 'MVR',
  };
  const setCurrencyBySpecialCondition = (cur?) => {
    if (cur?.regions?.length === 1) {
      return regionCurrencies[cur.regions[0].value]?.toLowerCase();
    } else if (
      cur?.retailers?.length === 1 &&
      cur?.retailers?.[0]?.value === 'EventNetworkStoreType'
    ) {
      return 'usd';
    } else if (
      (retailers.length === 1 &&
        retailers[0]?.value === 'EventNetworkStoreType') ||
      (selected_retailers.length === 1 &&
        selected_retailers[0]?.value === 'EventNetworkStoreType')
    ) {
      return 'usd';
    } else if (selected_regions.length === 1) {
      return (
        regionCurrencies[selected_regions[0]?.value]?.toLowerCase() ||
        currencies[0].value
      );
    } else if (regions.length === 1) {
      return (
        regionCurrencies[regions[0]?.value]?.toLowerCase() ||
        currencies[0].value
      );
    } else if (cur?.length === 0) {
      return currencies[0].value;
    } else {
      return currencies[0].value;
    }
  };
  const [currency, setCurrency] = React.useState(
    setCurrencyBySpecialCondition(),
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [toast, showToast] = React.useState({ visible: false, message: '' });
  const [computedAnalyticsData, setAnalyticsData] = React.useState<any>([]);
  const [computedAnalyticsData2, setAnalyticsData2] = React.useState<any>([]);
  let [tableData, setTableData] = React.useState<any>([]);
  const breakpointColumnsObj = {
    default: 3,
    1500: 3,
    1000: 2,
    550: 1,
  };
  const colors = [
    '#4896ca',
    '#f9ad40',
    '#3cb44b',
    '#41505d',
    '#f2fafb',
    '#f77a7f',
    '#96b4f0',
    '#eba692',
    '#c0d8dc',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#e6194b',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
  ];

  const handleClose = () => {
    showToast({ ...toast, visible: false });
  };

  const appendCurrency = (currency: any, value: any = 0) => {
    try {
      let number = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyFormat(currency),
      }).format(value);
      return number;
    } catch (e) {
      console.log(e);
      return 0;
    }
  };

  const formatValue = (value: string) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const fetchAnalyicsData = async () => {
    try {
      let store_ids = getSelectedStoreAppClipIds(
        stores,
        selected_retailers,
        selected_regions,
        active_stores,
        include_demo_stores,
        include_inactive_stores,
      );
      let regions = selected_regions.map((region: any) => region.value);
      let store_types = selected_retailers.map(
        (retailer: any) => retailer.value,
      );
      let params = {
        v1: 'analytics',
        token: cookies.app_token,
        store_types: store_types.join(),
        app_clip_ids: store_ids.join(),
        regions: regions.join(),
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        include_demo: include_demo_stores,
        include_inactive: include_inactive_stores,
        base_currency: currency,
      };
      let params_v2 = {
        v1: 'analytics_v2',
        token: cookies.app_token,
        store_types: store_types.join(),
        app_clip_ids: store_ids.join(),
        regions: regions.join(),
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        include_demo: include_demo_stores,
        include_inactive: include_inactive_stores,
        base_currency: currency,
      };

      let retailerTransactions = retailerBreakdownApi
          .get({ ...params_v2, base_currency: currency })
          .then((res: any) => res)
          .catch((e: Error) => e),
        transactionBreakdown = transactionBreakdownApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e),
        itemsScanned = itemsScannedApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e),
        ratings = ratingsApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e),
        loginSignupAnalytics = loginSignupAnalyticsApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e);

      return Promise.allSettled([
        retailerTransactions,
        transactionBreakdown,
        itemsScanned,
        ratings,
        loginSignupAnalytics,
      ]).then(res => {
        let [
          retailerTransactionsRes,
          transactionBreakdownRes,
          itemsScannedRes,
          ratingsRes,
          loginSignupAnalyticsRes,
        ] = res;
        let data = {
          ...get(retailerTransactionsRes, 'value.data.data', {}),
          payment_method_breakdown: get(
            transactionBreakdownRes,
            'value.data.data.payment_method_breakdown',
            {},
          ),
          payment_breakdown_by_platform: get(
            transactionBreakdownRes,
            'value.data.data.platform_breakdown',
            {},
          ),
          ...get(itemsScannedRes, 'value.data.data', {}),
          ...get(ratingsRes, 'value.data.data', {}),
          ...get(loginSignupAnalyticsRes, 'value.data.data', {}),
        };
        constructAnalyticsData(data);
        return data;
      });
    } catch (e) {
      console.log({ e });
    }
  };
  let analyticsDisclaimer = 'analyticsDisclaimer';
  const checkLocalTimeDisplay = () => {
    if (active_stores.length > 15) {
      return 'analyticsDisclaimer';
    }
    active_stores?.filter((store: any) => {
      if (
        store?.store_type === 'LovesStoreType' ||
        // store?.store_type === 'GradioseStoreType' ||
        store?.store_type === 'EventNetworkStoreType' ||
        store?.store_type === 'DubaiDutyFreeStoreType'
      ) {
        analyticsDisclaimer = 'analyticsLocalTimeDisclaimer';
      }
    });
    return analyticsDisclaimer;
  };

  const {
    isLoading,
    data: analyticsData,
    isFetching,
    error,
  } = useQuery(
    [
      'analyticsData',
      'store_id',
      startDate,
      endDate,
      selected_regions,
      selected_retailers,
      active_stores,
      currency,
    ],
    fetchAnalyicsData,
    { refetchOnWindowFocus: false, refetchInterval: 180000 },
  );

  const constructTableData = (data: any = {}): any => {
    tableData = [];
    data?.['retailer_breakdown']
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((retailerData: any, index: number) => {
        tableData.push([
          {
            title: 'Store name',
            value: retailerData.store_name,
          },
          { title: 'Region', value: retailerData.region },
          {
            title: 'Txns',
            value: retailerData.total_retailer_transaction_count,
          },
          {
            title: 'Value',
            value: appendCurrency(
              currency,
              retailerData.total_retailer_basket_value_converted,
            ),
          },
          {
            title: 'Value (VAT excl.)',
            value: appendCurrency(
              currency,
              retailerData.total_retailer_tax_excluded_basket_value_converted,
            ),
          },
          {
            title: 'AOV',
            value: appendCurrency(
              currency,
              retailerData.average_retailer_basket_value_converted,
            ),
          },
          {
            title: 'Items',
            value: retailerData.total_retailer_purchased_item,
          },
          {
            title: 'ABS',
            value: retailerData.average_retailer_basket_size,
          },
          {
            title: 'Ratings',
            value: retailerData.average_rating,
          },
        ]);
      });
    if (data.retailer_breakdown?.length === 0 || !data.retailer_breakdown) {
      tableData = [
        [
          {
            title: 'Store name',
          },
          { title: 'Region' },
          {
            title: 'Txns',
          },
          {
            title: 'Value',
          },
          {
            title: 'Value (VAT excl.)',
          },
          {
            title: 'AOV',
          },
          {
            title: 'Items',
          },
          {
            title: 'ABS',
          },
          {
            title: 'Ratings',
          },
        ],
      ];
    }
    setTableData(tableData);
  };

  const constructAnalyticsData = (analyticsData: any = {}): any => {
    constructTableData(analyticsData);
    let csData = get(analyticsData, 'rating_breakdown', {});
    let csKeys = Object.keys(csData);
    let payment_method_data = get(
      analyticsData,
      'payment_method_breakdown',
      {},
    );

    let payment_method_keys = Object.keys(payment_method_data).filter(
      (key: any) =>
        [
          'ali_pay',
          'amazon_pay',
          'ideal',
          'mobilepay',
          'swish',
          'wechat_pay',
          'paypal',
          'apple_pay',
          'google_pay',
          'credit_card',
        ].includes(key) || payment_method_data[key],
    );

    const column_1 = [
      {
        type: 'text',
        title: '',
        data: {
          type: 'custom',
          body: [
            {
              title: intl.formatMessage({ id: 'totalTransactions' }),
              value: formatValue(get(analyticsData, 'total_txn_count', '0')),
              icon: ShoppingCart11,
            },
            {
              title: intl.formatMessage({ id: 'averageBasketSize' }),
              value: get(analyticsData, 'average_basket_size', '0'),
              icon: ShoppingCart1,
            },
            {
              title: intl.formatMessage({ id: 'transactionValue' }),
              value: get(analyticsData, 'use_total_order_value', false)
                ? appendCurrency(
                    currency,
                    get(analyticsData, 'total_basket_value_converted', '0'),
                  )
                : '--',
              icon: TransactionValue,
              title2: '(VAT excl.)',
              value2: get(analyticsData, 'use_total_order_value', false)
                ? appendCurrency(
                    currency,
                    get(
                      analyticsData,
                      'total_tax_excluded_basket_value_converted',
                      '0',
                    ),
                  )
                : '--',
            },
            {
              title: intl.formatMessage({ id: 'averageTransactionValue' }),
              value: get(analyticsData, 'use_total_order_value', false)
                ? appendCurrency(
                    currency,
                    get(analyticsData, 'average_order_value', '0'),
                  )
                : '--',
              icon: AvgBasketValue,
            },
          ],
        },
      },
    ];
    const column_2 = [
      {
        type: 'text',
        title: '',
        data: {
          body: [
            {
              title: intl.formatMessage({ id: 'totalItemPurchased' }),
              value: formatValue(
                get(analyticsData, 'total_purchased_item', '0'),
              ),
              icon: TotalItemsPurchased,
            },
            {
              title: intl.formatMessage({ id: 'itemsScanned' }),
              value: formatValue(get(analyticsData, 'total_item_scanned', '0')),
              icon: ItemsScanned,
            },
            {
              title: intl.formatMessage({ id: 'newUserSignups' }),
              value: formatValue(get(analyticsData, 'signup', '0')),
              icon: SignupSessions,
            },
            {
              title: intl.formatMessage({ id: 'guestUsers' }),
              value: formatValue(get(analyticsData, 'login', '0')),
              icon: LoginSessions,
            },
          ],
        },
      },
    ];
    const column_3 = [
      {
        type: 'pie',
        title: intl.formatMessage({ id: 'paymentMethods' }),
        rootDirection: 'column-reverse',
        rootAlignment: 'flex-start',
        size: 'large',
        total: get(analyticsData, 'total_txn_count', '0'),
        data: payment_method_keys.map((item, index) => {
          return {
            value: payment_method_data[item],
            title: startCase(item),
            color: colors[index],
          };
        }),
      },

      {
        type: 'ratings',
        title: intl.formatMessage({ id: 'customerSatisfaction' }),
        data: {
          barData: csKeys.map((item, index) => {
            return {
              value: csData[item],
              title: item,
            };
          }),
          textData: {
            title: intl.formatMessage({ id: 'avgExp' }),
            value: get(analyticsData, 'average_rating', '0'),
          },
        },
      },
      {
        type: 'pie',
        title: intl.formatMessage({ id: 'transactionsByPlatform' }),
        rootDirection: 'column-reverse',
        rootAlignment: 'flex-start',
        total: get(analyticsData, 'total_txn_count', '0'),
        data: [
          {
            key: 1,
            value: get(analyticsData, 'payment_breakdown_by_platform.ios', ''),
            title: 'iOS',
            formatText: false,
            color: colors[0],
          },
          {
            key: 2,
            value:
              get(analyticsData, 'payment_breakdown_by_platform.android', '') -
              (get(
                analyticsData,
                'payment_breakdown_by_platform.android_kiosk',
                '',
              ) +
                get(
                  analyticsData,
                  'payment_breakdown_by_platform.android_ps20',
                  '',
                )),
            title: 'Android',
            color: colors[1],
          },
          {
            key: 3,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_kiosk',
              '',
            ),
            title: 'Android Kiosk',
            color: colors[2],
          },
          {
            key: 4,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_ps20',
              '',
            ),
            title: 'Android PS20',
            color: colors[16],
          },
          {
            key: 5,
            value: get(analyticsData, 'payment_breakdown_by_platform.web', ''),
            title: 'Webapp',
            color: colors[5],
          },
          // TODO: update android_cashier_kiosk key name as per BE implementation (GPP-5294)
          {
            key: 6,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_cashier_kiosk',
              0,
            ),
            title: 'Cashier Kiosk',
            color: colors[6],
          },
          {
            key: 7,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_mpos',
              0,
            ),
            title: 'Android mPOS',
            color: colors[9],
          },
        ],
      },
    ];
    setAnalyticsData([...column_1, ...column_2]);
    setAnalyticsData2([...column_3]);
  };

  const chartIterator = (item: any) => {
    switch (item.type) {
      case 'text':
        return <DataStats data={item.data} />;
      case 'pie':
        return (
          <Chart
            data={item.data}
            title={item.title}
            rootAlignment={item.rootAlignment}
            rootDirection={item.rootDirection}
            size={item.size}
            total={item.total}
          />
        );
      case 'bar':
        return <BarChart data={item.data} title={item.title} />;
      case 'ratings':
        return <RatingsChart data={item.data} title={item.title} />;
      default:
        return <></>;
    }
  };
  const TableBottom = (pageCount: any) => {
    const active = 'bg-primary text-white';
    const disabled = 'bg-[#E5E7EB] text-[#637381] pointer-events-none';
    const pagelen = Math.ceil(pageCount.pagination / 10);
    const pageArr = pagelen ? [...Array(pagelen).keys()] : [1];
    return (
      <div className="pb-10 pt-3 text-center ">
        <ul className="inline-flex items-center justify-center gap-1 rounded-[3px] bg-white p-2 shadow-[0_1px_3px_0_rgba(0,0,0,0.13)]">
          <li>
            <button
              onClick={async () => {
                page > 0 && setPage(page - 1);
              }}
              className={`flex h-[35px] min-w-[25px] items-center justify-center rounded-[3px] px-[10px] text-base font-medium text-dark hover:bg-gray-2 ${
                page === 0 ? disabled : ''
              }`}
            >
              <svg
                className={` ${page === 0 ? disabled : ''}`}
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.8125H4.15625L9.46875 4.40625C9.75 4.125 9.75 3.6875 9.46875 3.40625C9.1875 3.125 8.75 3.125 8.46875 3.40625L2 9.96875C1.71875 10.25 1.71875 10.6875 2 10.9688L8.46875 17.5312C8.59375 17.6562 8.78125 17.75 8.96875 17.75C9.15625 17.75 9.3125 17.6875 9.46875 17.5625C9.75 17.2812 9.75 16.8438 9.46875 16.5625L4.1875 11.2188H17.5C17.875 11.2188 18.1875 10.9062 18.1875 10.5312C18.1875 10.125 17.875 9.8125 17.5 9.8125Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </li>

          {pageArr?.map((row, index) => (
            <li>
              <button
                className={`flex h-[35px] min-w-[25px] items-center justify-center rounded-[3px] px-[14px] text-base font-medium text-dark hover:bg-gray-2 ${
                  page === index ? active : ''
                }`}
                onClick={() => {
                  setPage(index);
                }}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => {
                page + 1 < pagelen && setPage(page + 1);
              }}
              className={`flex h-[35px] min-w-[25px] items-center justify-center rounded-[3px] px-[10px] text-base font-medium text-dark hover:bg-gray-2 ${
                page + 1 === pagelen ? disabled : ''
              }`}
            >
              <svg
                className={` ${page + 1 === pagelen ? disabled : ''}`}
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 10L11.5312 3.4375C11.25 3.15625 10.8125 3.15625 10.5312 3.4375C10.25 3.71875 10.25 4.15625 10.5312 4.4375L15.7812 9.78125H2.5C2.125 9.78125 1.8125 10.0937 1.8125 10.4688C1.8125 10.8438 2.125 11.1875 2.5 11.1875H15.8437L10.5312 16.5938C10.25 16.875 10.25 17.3125 10.5312 17.5938C10.6562 17.7188 10.8437 17.7812 11.0312 17.7812C11.2187 17.7812 11.4062 17.7188 11.5312 17.5625L18 11C18.2812 10.7187 18.2812 10.2812 18 10Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </li>
        </ul>
      </div>
    );
  };

  useEffect(() => {
    constructTableData(analyticsData);
  }, [page]);
  const selectHandler = (data: any) => {
    setCurrency(data.value);
    return data.value;
  };
  const selectDateHandler = (data: any) => {
    console.log(data);
    setdateTitle(data.title);
    dispatch({
      type: 'CHANGE_ANALYTICS_START_DATE',
      payload: data.value,
    });
    dispatch({
      type: 'CHANGE_ANALYTICS_END_DATE',
      payload: data.value,
    });
    return data.value;
  };
  const rightAccessory = () => {
    return (
      <Box className={classes.dateRange}>
        <Box
          style={{
            marginRight: 8,
            marginLeft: 16,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <InputLabel id="demo-simple-select-filled-label">Currency</InputLabel>
          <DropDown
            title={currency.toUpperCase()}
            currency={currencies}
            selectHandler={selectHandler}
          ></DropDown>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 'auto',
            marginBottom: 0,
          }}
        >
          <Box
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          ></Box>
          <DateRangePicker
            handleDateChange={dateValue => {
              console.log('analytics date', dateValue);
              setdateTitle('Quick Picks');
              dispatch({
                type: 'CHANGE_ANALYTICS_START_DATE',
                payload: dateValue.startDate,
              });
              dispatch({
                type: 'CHANGE_ANALYTICS_END_DATE',
                payload: dateValue.endDate,
              });
            }}
          ></DateRangePicker>
        </Box>
        <Box
          style={{
            marginLeft: 8,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            marginTop: '15px',
          }}
        >
          <DropDown
            title={dateTitle}
            currency={dateRng}
            selectHandler={selectDateHandler}
          ></DropDown>
        </Box>
      </Box>
    );
  };
  useEffect(() => {
    eventBus.on('storeSelectorUpdate', data => {
      setCurrency(setCurrencyBySpecialCondition(data));
    });
  }, []);
  useEffect(() => {
    const init = async () => {
      datadogRum.addAction('dashboard-analytics');
    };
    init();
  }, []);

  return (
    <Box className={classes.root}>
      <Header
        title={<FormattedMessage id="analytics" defaultMessage="Analytics" />}
        subtitle2={
          <FormattedMessage
            id={checkLocalTimeDisplay()}
            defaultMessage="*Data is calculated in UTC timezone"
          />
        }
        rightAccessory={rightAccessory}
      />
      <Box className={classes.bodyContainer}>
        {isLoading ? (
          <Box className={classes.noOrdersContainer}>
            <CircularProgress color="primary" size={30} />
          </Box>
        ) : (
          <Box>
            <Masonry
              breakpointCols={2}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {computedAnalyticsData.map((chart: any, index: number) => {
                if (chart.data.length === 0) return;
                return <div key={index}>{chartIterator(chart)}</div>;
              })}
            </Masonry>
            <Masonry
              breakpointCols={3}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {computedAnalyticsData2.map((chart: any, index: number) => {
                if (chart.data.length === 0) return;
                return <div key={index}>{chartIterator(chart)}</div>;
              })}
            </Masonry>
            <DataTable tableData={tableData}></DataTable>
            <TableBottom
              pagination={analyticsData?.['retailer_breakdown']?.length}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        open={toast.visible}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {toast?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default React.memo(Analytics);
