import produce from 'immer';
import {
  CHANGE_ANALYTICS_START_DATE,
  CHANGE_ANALYTICS_END_DATE,
} from '../actions/action_types';

const INITIAL_STATE = {
  startDate: new Date(),
  endDate: new Date(),
};

const analytics = produce((draft: any, action: any) => {
  switch (action.type) {
    case CHANGE_ANALYTICS_START_DATE:
      draft.startDate = action.payload;
      break;
    case CHANGE_ANALYTICS_END_DATE:
      draft.endDate = action.payload;
      break;
  }
}, INITIAL_STATE);

export default analytics;
