import { SvgIcon } from '@material-ui/core';
import React from 'react';

const ArrowDownIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      fill="#222b45"
      viewBox="0 0 24 24"
    >
      <g data-name="Layer 2">
        <g data-name="chevron-down">
          <rect width="24" height="24" opacity="0" />
          <path d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default ArrowDownIcon;
