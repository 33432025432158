import React, { memo } from 'react';
import { Card, Box, Typography, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { verificationReasons } from 'src/utils';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import AlertIcon from 'src/assets/svgIcons/AlertIcon';
import ArrowRight from 'src/assets/svgIcons/ArrowRight';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '680px',
    },
    padding: 8,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
    marginRight: 15,
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
  },
  order: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 4,
  },
  date: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 4,
  },
  storeName: {
    fontSize: '13px',
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 4,
    textAlign: 'left',
  },
  orderDateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bodyItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 30,
  },
  item1: {
    fontSize: 13,
    color: theme.palette.color_text_grey.main,
  },
  item2: {
    fontSize: 13,
    color: theme.palette.color_dark_text.main,
  },

  issue: {
    marginTop: 16,
    textTransform: 'none',
    alignSelf: 'flex-start',
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: 16,
    },
    color: theme.palette.warning.main,
  },

  buttonLeft: {
    width: '167px',
    backgroundColor: `${theme.palette.error.main}1A`,
    border: `1px solid ${theme.palette.error.main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: theme.palette.error.main,
  },

  buttonRight: {
    width: '167px',
    backgroundColor: `${theme.palette.success.main}`,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: `${theme.palette.success.main}`,
    },
  },

  buttonLeftRipple: {
    backgroundColor: `${theme.palette.error.main}1A`,
  },
  buttonRightRipple: {
    color: `${theme.palette.success.main}`,
  },

  footer: {
    display: 'flex',
    marginTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'center',
  },
}));

const itemList = [
  {
    title: <FormattedMessage id="total" defaultMessage="Total" />,
  },
  {
    title: <FormattedMessage id="items" defaultMessage="Items" />,
  },
  {
    title: <FormattedMessage id="customer" defaultMessage="Customer" />,
  },
];

const VerificationCard = ({ item, showAlert, setModalData }: any) => {
  const { code, date, reason, store_name, tags, id, items } = item;

  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const theme: any = useTheme();

  const itemsLength = items.length;

  const navigateToDetails = () => {
    history.push({
      pathname: `/verificationDetails/`,
      state: {
        items: JSON.stringify(item),
      },
    });
  };
  return (
    <Card className={classes.root} variant="outlined">
      <Box className={classes.header} onClick={navigateToDetails}>
        <Box className={classes.orderDateTimeContainer}>
          <Typography className={classes.order}>{`${intl.formatMessage({
            id: 'request',
          })} #${code}`}</Typography>
          <Box
            style={{
              display: 'flex',
            }}
          >
            <Typography className={classes.date}>
              {moment(date).format('DD/MM/YY HH:mm')} ( UTC{' '}
              {moment(date).utc().format('HH:mm')} ){' '}
            </Typography>
          </Box>
          <Typography className={classes.storeName}>{store_name}</Typography>
        </Box>
      </Box>
      <Divider light />
      <Button
        startIcon={<AlertIcon />}
        endIcon={<ArrowRight />}
        className={classes.issue}
        onClick={navigateToDetails}
      >
        {verificationReasons(reason, tags, intl)}
      </Button>
      <Box
        className={classes.footer}
        borderColor={theme.palette.color_light_grey.main}
      >
        <Button
          className={classes.buttonLeft}
          variant="outlined"
          onClick={() => {
            showAlert(true);
            setModalData({
              modalText: intl.formatMessage({ id: 'confirmRejectCheck' }),
              action: 'reject',
              sessionId: id,
            });
          }}
        >
          <FormattedMessage
            id="reject"
            defaultMessage="REJECT"
          ></FormattedMessage>
        </Button>
        <Button
          className={classes.buttonRight}
          variant="contained"
          TouchRippleProps={{
            classes: { root: classes.buttonRightRipple },
          }}
          onClick={() => {
            setModalData({
              modalText: intl.formatMessage({ id: 'confirmApproveCheck' }),
              action: 'accept',
              sessionId: id,
            });
            showAlert(true);
          }}
        >
          <FormattedMessage
            id="accept"
            defaultMessage="ACCEPT"
          ></FormattedMessage>
        </Button>
      </Box>
    </Card>
  );
};

export default memo(VerificationCard);
