import { Subject } from 'rxjs';

const subscriber = new Subject(null);

const messageService = {
  send: {
    function(msg) {
      subscriber.next(msg);
    },
  },
};

export { subscriber, messageService };
