import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateRangeOutlined, KeyboardArrowDown } from '@material-ui/icons';

const DatePicker = (props: any) => {
  let {
    label,
    date,
    handleDateChange,
    style = {},
    startDate,
    endDate,
    isFromDateTimeFilter,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        minDate={startDate}
        maxDate={endDate || new Date()}
        autoOk
        disableFuture
        margin="normal"
        id="date-picker-dialog"
        label={label}
        format="dd/MM/yyyy"
        value={date}
        onChange={handleDateChange}
        size="small"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        keyboardIcon={
          isFromDateTimeFilter ? (
            <DateRangeOutlined htmlColor={'#231F20'} />
          ) : (
            <KeyboardArrowDown />
          )
        }
        style={style}
        placeholder={'dd/mm/yyyy'}
        InputProps={{ inputProps: { disabled: true } }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
