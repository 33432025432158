import resource from '../resource';

export const analyticsApi = resource('/dashboard-analytics/');
export const retailerBreakdownApi = resource(
  '/transaction-retailer-breakdown/',
);
export const transactionBreakdownApi = resource('/transaction-breakdown/');
export const itemsScannedApi = resource('/item-scanned-analytics/');
export const ratingsApi = resource('/ratings-analytics/');
export const loginSignupAnalyticsApi = resource('/login-signup-analytics/');
