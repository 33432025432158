import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function CancelledRefundedIcon(props: any) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Layer 2">
        <g data-name="close-circle">
          <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z" />
          <path d="M14.71 9.29a1 1 0 00-1.42 0L12 10.59l-1.29-1.3a1 1 0 00-1.42 1.42l1.3 1.29-1.3 1.29a1 1 0 000 1.42 1 1 0 001.42 0l1.29-1.3 1.29 1.3a1 1 0 001.42 0 1 1 0 000-1.42L13.41 12l1.3-1.29a1 1 0 000-1.42z" />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CancelledRefundedIcon;
