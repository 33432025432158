import React, { useState } from 'react';
import English from '../../assets/locale/en.json';
import Spanish from '../../assets/locale/es.json';
import French from '../../assets/locale/fr.json';
import Norwegian from '../../assets/locale/no.json';
import { IntlProvider } from 'react-intl';

interface Languages {
  [name: string]: any;
}

const languages: Languages = {
  en: English,
  es: Spanish,
  fr: French,
  no: Norwegian,
};

let local = navigator.language;
local = local.includes('-') ? local.split('-')[0] : local;

const storedLanguage = localStorage.getItem('DASHBOARD_APP_LANGUAGE');
if (storedLanguage && !!languages[storedLanguage]) {
  local = storedLanguage;
} else {
  if (!languages[local]) {
    local = 'en'; // default for regions not currently supported by our platform for i18n
  }
  localStorage.setItem('DASHBOARD_APP_LANGUAGE', local);
}

const defaultLanguage = languages[local];

export const LanguageContext = React.createContext<any>({
  local,
  changeLanguage: () => {},
});

const LanguageProvider = ({ children }: any) => {
  const [locale, setLoacale] = useState(local);
  const [language, setLanguage] = useState(defaultLanguage);

  const changeLanguage = (locale: any) => {
    setLoacale(locale);
    setLanguage(languages[locale]);
    localStorage.setItem('DASHBOARD_APP_LANGUAGE', locale);
  };
  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider messages={language} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
