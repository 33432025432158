import produce from 'immer';
import {
  SET_CURRENT_STORE_DATA,
  SET_STORES,
  SET_REGIONS,
  SET_RETAILERS,
  SET_ACTIVE_STORES,
  SET_SELECTED_RETAILERS,
  SET_SELECTED_REGIONS,
  INCLUDE_DEMO_STORES,
  INCLUDE_INACTIVE_STORES,
  SET_FCM_TOKEN,
} from '../actions/action_types';

const INITIAL_STATE = {
  active_store: {
    store_id: '',
    store_name: '',
    store_type: '',
    store_currency: '',
    store_item_source: '',
    retailer: '',
  },
  stores: [],
  active_stores: [],
  regions: [],
  retailers: [],
  selected_regions: [],
  selected_retailers: [],
  include_demo_stores: false,
  include_inactive_stores: false,
  fcm_token: '',
};

const store = produce((draft, action) => {
  switch (action.type) {
    case SET_CURRENT_STORE_DATA:
      draft.active_store = action.payload;
      break;
    case SET_STORES:
      draft.stores = action.payload;
      break;
    case SET_ACTIVE_STORES:
      draft.active_stores = action.payload;
      break;
    case SET_REGIONS:
      draft.regions = action.payload;
      break;
    case SET_RETAILERS:
      draft.retailers = action.payload;
      break;
    case SET_SELECTED_RETAILERS:
      draft.selected_retailers = action.payload;
      break;
    case SET_SELECTED_REGIONS:
      draft.selected_regions = action.payload;
      break;
    case INCLUDE_DEMO_STORES:
      draft.include_demo_stores = action.payload;
      break;
    case INCLUDE_INACTIVE_STORES:
      draft.include_inactive_stores = action.payload;
      break;
    case SET_FCM_TOKEN:
      draft.fcm_token = action.payload;
      break;
  }
}, INITIAL_STATE);

export default store;
