import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Hidden,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NavigationTopBar } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import MishipayBackground from '../../assets/new-bg.svg';
import MishipayLogo from '../../assets/mishipay-logo.png';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { forgotPasswordApi } from 'src/services/user';
import { datadogLogs } from '@datadog/browser-logs';

const useStyles = makeStyles((theme: any) => ({
  paperContainer: {
    width: '100vw',
    height: '100vh',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${MishipayBackground})`,
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    objectFit: 'contain',
  },
  innerContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  textField: {
    width: '100%',
    maxWidth: '343px',
    marginTop: 20,
  },

  logoContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    objectFit: 'contain',
    height: '60px',
  },

  innerContainer1: {
    display: 'flex',
    flex: 0.3,
  },

  innerContainer2: {
    display: 'flex',
    flex: 0.7,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  buttonContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '343px',
    marginTop: 20,
  },

  buttonLogin: {
    flex: 1,
    height: '40px',
    color: 'white',
    fontWeight: 'bold',
  },

  forgotPassword: {
    color: theme.palette.color_dark_text.main,
    textAlign: 'left',
    marginLeft: 32,
    marginRight: 32,
  },

  login: {
    color: theme.palette.color_dark_text.main,
  },

  loginLink: { textDecoration: 'none', display: 'flex', marginTop: 20 },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    top: 90,
    display: 'flex',
    justifyContent: 'center',
  },

  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [inputs, setInputs] = useState({
    username: '',
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    let timer: any = null;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
        history.goBack();
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  const handleInputChange = (e: any) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    reset();
  };

  const forgotPassword = (formData: any) =>
    forgotPasswordApi.create({}, JSON.stringify(formData));

  const { isLoading, mutate, reset } = useMutation(forgotPassword, {
    onError: (serverError: any) => {
      datadogLogs.logger.error('forgotPassword ErrorHandler', serverError);
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: async successData => {
      console.log('Success Data', successData);
      setSuccess(true);
    },
  });

  const handleLogin = () => {
    let deepLinkUrl =
      window.location.href.split('forgot-password')[0] + 'reset-password/';
    if (inputs.username.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyUsername' }));
    } else {
      mutate({
        username: inputs.username,
        deepLinkUrl: deepLinkUrl,
      });
    }
  };

  return (
    <Paper className={classes.paperContainer}>
      <Hidden only={['xs']} implementation="css">
        {isLoading && <LinearProgress color="primary" />}
      </Hidden>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}

      {success && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="success"
          >
            {intl.formatMessage({ id: 'forgotPasswordSuccess' })}
          </Alert>
        </Box>
      )}
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          <NavigationTopBar
            titlekey="forgotPassword"
            defaultValue="Forgot password"
          />
          <Box className={classes.innerContainer1}>
            <Box component="div" className={classes.logoContainer}>
              <img
                src={MishipayLogo}
                alt="Mishipay Logo"
                className={classes.logo}
              />
            </Box>
          </Box>
          <Typography className={classes.forgotPassword}>
            {intl.formatMessage({ id: 'forgotPasswordTitle' })}
          </Typography>
          <Box className={classes.innerContainer2}>
            <TextField
              className={classes.textField}
              name="username"
              placeholder={intl.formatMessage({ id: 'username' })}
              onChange={handleInputChange}
            />

            <Box className={classes.buttonContainer}>
              <Button
                className={classes.buttonLogin}
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={isLoading}
              >
                <FormattedMessage
                  id="send"
                  defaultMessage="SEND"
                ></FormattedMessage>
              </Button>
            </Box>
            <Link to="/" className={classes.loginLink} replace>
              <Typography className={classes.login}>
                {intl.formatMessage({ id: 'rememberPassword' })}
              </Typography>
              <Typography className={classes.login}>
                <strong>{intl.formatMessage({ id: 'login' })}</strong>
              </Typography>
            </Link>
          </Box>
        </Box>
        <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
          {isLoading && (
            <Box className={classes.mobileLoaderContainer}>
              <Box className={classes.mobileLoader}>
                <CircularProgress
                  className={classes.circle}
                  size={25}
                  thickness={5}
                ></CircularProgress>
                <Typography>Loading...</Typography>
              </Box>
            </Box>
          )}
        </Hidden>
      </Box>
    </Paper>
  );
};

export default ForgotPassword;
