import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { Header } from '../../components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const ItemReport = () => {
  const active_stores = useSelector((state: any) => state.store.active_stores);
  return (
    <Box>
      <Header
        title={
          <FormattedMessage id="itemReport" defaultMessage="Item Report" />
        }
        subtitle={
          <FormattedMessage id="itemReportOf" defaultMessage="Item report of" />
        }
        rightAccessory={() => {
          return <></>;
        }}
      />

      <Box component="div">
        {active_stores.length === 1 &&
        active_stores[0]['store_type'] === 'MLSEStoreType' ? (
          <div>
            <iframe
              width="100%"
              height="800px"
              src="https://lookerstudio.google.com/embed/reporting/9a7ca1a9-01cb-4554-8f26-589ac1b05808/page/kEYvD"
              sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
            ></iframe>
          </div>
        ) : (
          <div>
            <FormattedMessage
              id="noReports"
              defaultMessage="No Reports Available"
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ItemReport;
