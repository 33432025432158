const DataTable = ({ tableData }: any) => {
  const TableHead = ({ data }) => {
    return (
      <thead>
        <tr className="bg-primary text-left">
          {data?.[0]?.map((header, index) => (
            <th
              className={`px-[15px] py-[15px] pr-[10px] first:pl-[45px] last:pr-[45px] text-base font-medium text-white`}
              key={index}
            >
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const TableBody = ({ data }) => {
    return data[0]?.[0]?.value ? (
      <tbody>
        {data?.map((row, index) => (
          <tr key={index}>
            {row.map((item, i) => (
              <td className="px-[15px] py-[15px] pr-[10px] border-t border-stroke first:pl-[45px] last:pr-[45px]">
                <h5 className="text-base text-body-color text-[#111928] text-left text-[14px]">
                  {item.value}
                </h5>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td
            colSpan={data[0]?.length}
            className="px-[15px] py-[15px] pr-[10px] pl-[45px] border-t border-stroke"
          >
            No available data
          </td>
          <td></td>
        </tr>
      </tbody>
    );
  };

  return (
    <div className="w-full">
      <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
          <div className="max-w-full overflow-x-auto rounded-xl shadow-[0px_3px_8px_0px_rgba(0,0,0,0.08)] bg-white dark:bg-dark-2">
            <table className="w-full table-auto">
              <TableHead data={tableData} />
              <TableBody data={tableData} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
