import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import reducers from './reducers';
// import autoMergeLevel2 from 'redux persist/lib/stateReconciler/autoMergeLevel2'
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  blacklist: ['theme'],
  whitelist: ['screens', 'users', 'orders', 'settlements', 'store', 'user'],
  storage,
  // stateReconciler: autoMergeLevel2,
};

let middleware = [thunk];

if (process.env.REACT_APP_ENV_TYPE !== 'Production') {
  const reduxImmutableStateInvariant =
    require('redux-immutable-state-invariant').default();
  middleware = [...middleware, reduxImmutableStateInvariant, logger];
} else {
  middleware = [...middleware];
}

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware),
);
export const persistor = persistStore(store);

// export const configureStore = () => createStore(persistedReducer, applyMiddleware(thunk));
